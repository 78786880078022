import React, { useRef, useState } from "react";
import uniqueId from "lodash.uniqueid";
import styled, { css } from "styled-components";
import { Color } from "../types";
import Popover, { Placement } from "./Popover";
import Text from "./Text";
import SplitView from "./SplitView";
import Flex from "./Flex";

export interface MetricProps extends React.HTMLAttributes<HTMLDivElement> {
  borderColor?: Color;
  color?: Color;
  inverse?: boolean;
  loading?: boolean;
  subtitle?: string;
  title?: string;
  value?: string | Record<string, string | number>;
  tooltip?: React.ReactNode;
  shadowColor?: Color;
  titleColor?: Color;
}

const Loader = styled.div<{ $color?: Color }>`
  font-size: 10px;
  margin: 0.75rem auto;
  text-indent: -9999em;
  width: 5em;
  height: 5em;
  border-radius: 50%;
  background: var(--white);
  background: -moz-linear-gradient(
    left,
    var(--white) 10%,
    rgba(255, 255, 255, 0) 42%
  );
  background: -webkit-linear-gradient(
    left,
    var(--white) 10%,
    rgba(255, 255, 255, 0) 42%
  );
  background: -o-linear-gradient(
    left,
    var(--white) 10%,
    rgba(255, 255, 255, 0) 42%
  );
  background: -ms-linear-gradient(
    left,
    var(--white) 10%,
    rgba(255, 255, 255, 0) 42%
  );
  background: linear-gradient(
    to right,
    var(--white) 10%,
    rgba(255, 255, 255, 0) 42%
  );
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);

  &:before {
    width: 50%;
    height: 50%;
    background: var(--${(props) => props.$color || "primary"});
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
  }
  &:after {
    background: var(--white);
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: "";
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export const StyledMetric = styled.div<{
  $borderColor?: Color;
  $color?: Color;
  $shadowColor?: Color;
  $inverse?: boolean;
}>`
  aspect-ratio: 1;
  justify-content: center;
  background-color: var(--white);
  border: 1px solid var(--${(props) => props.$borderColor || "primary"});
  border-radius: 0.25rem;
  box-shadow: -3px 3px 0px
    var(--${(props) => props.$shadowColor || props.$borderColor || "primary"});
  box-sizing: border-box;
  ${(props) =>
    props.$inverse
      ? css`
          background-color: var(--${props.$color || "primary"});
          color: var(--white) !important;
        `
      : css`
          color: var(--${props.$color || "primary"}) !important;
        `};
  display: flex;
  flex: 1 1 25%;
  flex-direction: column;
  gap: 1rem;
  overflow: hidden;
  padding: 0.5rem;
  position: relative;
  text-align: center;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  ${Text} {
    text-align: center;
  }

  @media (max-width: 800px) {
    aspect-ratio: auto;
    padding: 0.5rem 0.25rem 0.75rem;

    & > .title {
      font-size: 0.875rem;
    }
  }
`;

const InfoTooltip = styled.div`
  border: 1px solid var(--tertiary);
  border-radius: 100%;
  height: 1rem;
  pointer-events: all;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  user-select: none;
  width: 1rem;
  z-index: 3;

  ${Text} {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0;
    pointer-events: none;
    user-select: none;
  }
`;

const MetricSplitView = styled(SplitView)`
  padding: 0;
`;

const Metric = ({
  borderColor,
  color,
  shadowColor,
  titleColor,
  inverse,
  loading,
  subtitle,
  title,
  value,
  tooltip,
  ...props
}: MetricProps) => {
  const idRef = useRef(uniqueId("tooltip-"));
  const [isOpen, setIsOpen] = useState(false);

  return (
    <StyledMetric
      $borderColor={borderColor}
      $color={color}
      $shadowColor={shadowColor}
      $inverse={inverse}
      {...props}
    >
      {title && (
        <Text
          className="title"
          weight={500}
          color={titleColor || (inverse ? Color.white : color)}
          size="1rem"
          paddingBottom="0"
        >
          {title}
        </Text>
      )}
      {loading && <Loader $color={color} />}
      {!loading && (
        <>
          {typeof value === "object" && (
            <MetricSplitView padding="0">
              {Object.entries(value).map(([key, val], idx) => {
                const reactKey = `${key}-${val}-${idx}`;
                return (
                  <Flex
                    key={reactKey}
                    style={{
                      borderRight:
                        idx === 0 ? "1px solid var(--white)" : undefined,
                      flexDirection: "column",
                      padding: "1rem 0.5rem"
                    }}
                  >
                    <Text
                      weight={500}
                      color={inverse ? Color.white : color}
                      style={{
                        fontSize: "1rem",
                        margin: "0 0 1rem",
                        padding: 0
                      }}
                    >
                      {key}
                    </Text>
                    <Text
                      weight={500}
                      color={inverse ? Color.white : color}
                      style={{ fontSize: "1.4rem", margin: "0", padding: 0 }}
                    >
                      {val}
                    </Text>
                  </Flex>
                );
              })}
            </MetricSplitView>
          )}
          {typeof value !== "object" && (
            <Text
              className="value"
              weight={500}
              color={inverse ? Color.white : color}
              size="1.5rem"
              paddingBottom="0"
            >
              {value || "---"}
            </Text>
          )}
          {subtitle && (
            <Text weight={500} color={inverse ? Color.white : color}>
              {subtitle}
            </Text>
          )}
        </>
      )}
      {tooltip && (
        <>
          <InfoTooltip
            onMouseOver={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
            id={idRef.current}
          >
            <Text weight={500}>i</Text>
          </InfoTooltip>
          <Popover
            arrow
            color={Color.white}
            isOpen={isOpen}
            target={idRef.current}
            maxContent
            shadow
            placement={Placement.top}
          >
            <div style={{ padding: "0.25rem" }}>{tooltip}</div>
          </Popover>
        </>
      )}
    </StyledMetric>
  );
};

export default Metric;
