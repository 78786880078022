import React, { useState, useEffect, useContext, lazy, Suspense } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../../styles/AirbnbsForSale.scss";
import Header from "../../components/Header";
import Text from "../../components/Text";
import { AppContext } from "../../components/AppContext";

// Import the JSON file
import listingsData from "./2024-10-01_combined.json";

interface Listing {
  id: number;
  title: string;
  location: string;
  price: number;
  annualRevenue: number;
  image: string;
  bedrooms: number;
  bathrooms: number;
  sqft: number;
  capRate: number;
  featured: boolean;
}

const LazyImage = lazy(() => import("../../components/LazyImage"));

const ListingCard: React.FC<{ listing: Listing }> = ({ listing }) => (
  <Link
    to={`/airbnbs-for-sale-listings/${encodeURIComponent(listing.location)}`}
    target="_blank"
    style={{
      display: "block",
      textAlign: "center",
      background: "var(--white)",
      color: "white",
      padding: "8px",
      borderRadius: "4px",
      textDecoration: "none",
      position: "relative"
    }}
    className="listing-card-link"
  >
    {listing.featured && (
      <div
        style={{
          position: "absolute",
          top: "20px",
          right: "16px",
          background: "var(--eighth)",
          color: "white",
          padding: "4px 8px",
          borderRadius: "4px",
          zIndex: 1,
          marginBottom: "1.5rem",
          fontSize: "0.9em"
        }}
      >
        Featured: Current STR
      </div>
    )}
    <div
      style={{
        border: "1px solid #ddd",
        borderRadius: "8px",
        padding: "16px",
        // width: "300px",
        height: "500px", // Increased height
        cursor: "pointer",
        display: "flex",
        // margin: "10px",
        flexDirection: "column",
        justifyContent: "space-between" // Distribute content evenly
      }}
      className="listing-card"
    >
      <div>
        <h2
          style={{
            fontSize: "1.2em",
            marginBottom: "8px",
            marginTop: listing.featured ? "2rem" : "1rem"
          }}
        >
          {listing.location}
        </h2>
        <Suspense fallback={<div>Loading...</div>}>
          <LazyImage
            src={listing.image}
            alt={listing.title}
            style={{
              width: "100%",
              height: "200px",
              objectFit: "cover",
              marginBottom: "12px",
              borderRadius: "4px"
            }}
          />
        </Suspense>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "8px"
          }}
        >
          {/* <span>Price:</span> */}
          <span style={{ fontWeight: "bold", color: "var(--tertiary)" }}>
            ${listing.price.toLocaleString()}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: "12px",
            fontSize: "1em",
            color: "var(--quaternary)"
          }}
        >
          <span style={{ color: "var(--secondary)", fontWeight: "500" }}>
            {listing.bedrooms} beds
          </span>
          <span style={{ margin: "0 8px" }}>|</span>
          <span style={{ color: "var(--secondary)", fontWeight: "500" }}>
            {listing.bathrooms} baths
          </span>
          <span style={{ margin: "0 8px" }}>|</span>
          <span style={{ color: "var(--secondary)", fontWeight: "500" }}>
            {listing.sqft} sq ft
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "8px"
          }}
        >
          <span>Projected Revenue:</span>
          <span style={{ fontWeight: "500", color: "var(--tertiary)" }}>
            ${listing.annualRevenue.toLocaleString()}/year
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "8px"
          }}
        >
          <span>Cap Rate:</span>
          <span style={{ fontWeight: "500", color: "var(--tertiary)" }}>
            {listing.capRate.toFixed(2)}%
          </span>
        </div>
      </div>
    </div>
  </Link>
);

const ListingsPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [listings, setListings] = useState<Listing[]>([]);
  const { mobileSize } = useContext(AppContext);

  useEffect(() => {
    // Load the data from the imported JSON file and sort by featured status first, then cap rate
    setListings(
      listingsData
        .filter((listing) => listing.images && listing.images.length > 0)
        .map((listing) => ({
          ...listing,
          title: listing.address,
          location: listing.address,
          annualRevenue: parseFloat(listing.annualRevenue.toFixed(0)),
          image: listing.images[0],
          sqft: listing.sqft ?? 0,
          capRate: (listing.annualRevenue / listing.price) * 60,
          featured: listing.featured ?? false
        }))
        .sort((a, b) => {
          // Sort featured items first
          if (a.featured && !b.featured) return -1;
          if (!a.featured && b.featured) return 1;
          // Then sort by cap rate
          return b.capRate - a.capRate;
        })
    );
  }, []);

  const filteredListings = listings.filter(
    (listing) =>
      listing.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      listing.location.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div
      style={{
        background: "var(--white)",
        margin: "108px auto 0",
        padding: "20px"
      }}
    >
      <Helmet>
        <title>
          Airbnb Listings for Sale | Profitable Short-Term Rental Investments
        </title>
        <meta
          name="description"
          content="Explore our curated selection of profitable Airbnb properties for sale. Each listing comes with real revenue data, professional photos, and projected returns."
        />
        <meta
          name="keywords"
          content="Airbnb for sale, short-term rental investments, vacation rental properties, Airbnb investment opportunities"
        />
        <link
          rel="canonical"
          href="https://www.getchalet.com/airbnbs-for-sale"
        />
        <meta
          property="og:title"
          content="Airbnb Properties for Sale | Profitable STR Investments"
        />
        <meta
          property="og:description"
          content="Discover turnkey Airbnb investments with verified financials, high-yield properties, and instant income potential."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.getchalet.com/airbnbs-for-sale"
        />
        <meta
          property="og:image"
          content="https://www.getchalet.com/images/airbnbs-for-sale-featured.webp"
        />
      </Helmet>

      <Header
        style={{
          fontSize: "2em",
          marginBottom: "1rem",
          color: "var(--quaternary)"
        }}
      >
        Airbnb Listings for Sale
      </Header>
      {/* <Header
        size={3}
        style={{
          textAlign: "center",
          maxWidth: "800px",
          margin: "0 auto 30px"
        }}
      >
        Discover Turnkey Airbnb Investments: Verified Financials, High-Yield
        Properties, and Instant Income Potential
      </Header> */}
      <Text
        style={{
          textAlign: "center",
          maxWidth: mobileSize ? "100%" : "60%",
          margin: "0 auto",
          fontSize: "1.3rem",
          display: "block",
          color: "var(--tertiary)"
        }}
      >
        Explore our curated selection of profitable Airbnb properties. Each
        listing comes with real revenue data, professional photos, and projected
        returns. Start building your short-term rental empire today!
      </Text>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-start", // Align items to the left
          gap: "1rem", // Add gap between cards
          maxWidth: "90vw", // Adjust based on your design
          margin: "0 auto"
        }}
      >
        {filteredListings.map((listing) => (
          <ListingCard key={listing.id} listing={listing} />
        ))}
      </div>
    </div>
  );
};

export default ListingsPage;
