import styled, { css } from "styled-components";
import { Color } from "../types";

export enum Font {
  Oswald = "Oswald",
  Pacifico = "Pacifico",
  SourceSansPro = "Source Sans Pro"
}

export interface TextProps {
  align?: "left" | "center" | "right";
  block?: boolean;
  color?: Color;
  font?: Font;
  inverse?: boolean;
  lineHeight?: string;
  paddingBottom?: string;
  size?: string;
  weight?: 200 | 300 | 400 | 500 | 600 | 700;
  underline?: boolean;
}

const Text = styled.span<TextProps>`
  color: var(--${(props) => props.color});
  font-size: ${(props) => props.size};
  text-align: ${(props) => props.align};
  line-height: ${(props) => props.lineHeight};
  padding-bottom: ${(props) => props.paddingBottom};

  ${(props) =>
    props.block &&
    css`
      display: block;
    `}

  ${(props) => {
    if (props.font === Font.Pacifico) {
      return css`
        font-family: "Pacifico", "Times New Roman", cursive;
      `;
    }

    return css`
      font-family: ${props.font}, Impact, "Franklin Gothic Bold", sans-serif;
    `;
  }};
  font-weight: ${(props) => String(props.weight)};

  a {
    color: var(--${(props) => props.color});

    &:hover,
    &:visited {
      color: var(--${(props) => props.color});
    }
  }

  ${(props) =>
    props.underline &&
    css`
      text-decoration: underline;
    `};

  ${(props) =>
    props.inverse &&
    css`
      color: var(--white);
      padding: 0.25rem;
      position: relative;
      z-index: 0;

      &:before {
        background-color: var(--tertiary);
        border-radius: 0.25rem;
        content: "";
        height: 70%;
        left: -0.25rem;
        position: absolute;
        top: ${props.font === Font.SourceSansPro ? "55%" : "50%"};
        transform: translateY(-50%);
        width: calc(100% + 0.5rem);
        z-index: -1;
      }
    `}
`;

Text.defaultProps = {
  align: "left",
  color: Color.tertiary,
  font: Font.SourceSansPro,
  paddingBottom: "1rem",
  size: "1.3rem",
  lineHeight: "2.2rem",
  weight: 300
};

export default Text;
