import uniqueId from "lodash.uniqueid";
import React, {
  HTMLAttributes,
  useCallback,
  useRef,
  useContext,
  useState
} from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { AppContext } from "../AppContext";
import Popover, { Placement } from "../Popover";
import hexToRgb from "../hexToRgb";
import { StyledNavbarItem } from "./NavbarItem";
import { Color } from "../../types";
import { Colors } from "../GlobalStyle";
import Button, { ButtonType, StyledButton } from "../Button";

export interface MenuButtonProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  href: string;
}

export interface NavbarMenuProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
}

export const NavbarMenuList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0 auto;
  padding: 0;

  ${StyledNavbarItem},
  ${StyledButton} {
    color: var(--${Color.tertiary});
    border-bottom: 1px solid var(--${Color.tertiary});
    border-radius: 0;
    width: 100%;
    // border-bottom: none;
    // border-top: none;
    margin: 0;
    // padding: 0.1rem;

    > a {
      color: var(--${Color.white});
    }
  }
`;

const StyledMenuButton = styled.button`
  background-color: var(--tertiary);
  border: none;
  border-bottom: 1px solid var(--white);
  border-radius: 0rem;
  color: var(--white);
  font-size: 1rem;
  font-weight: 300;
  outline: none;
  padding: 1rem;
  width: 100%;

  &:hover {
    background-color: rgba(${hexToRgb(Colors.white)}, 0.1);
  }

  &:focus {
    background-color: rgba(${hexToRgb(Colors.white)}, 0.15);
  }

  a {
    color: var(--white);
    text-decoration: none;
  }
`;

const MenuButton = ({ href, onClick, ...props }: MenuButtonProps) => {
  const history = useHistory();
  const handleMousEnter = useCallback(
    (e: React.MouseEvent) => {
      history.push(href);
      onClick?.(e as any);
    },
    [history]
  );

  return <StyledMenuButton {...props} onMouseEnter={handleMousEnter} />;
};

MenuButton.displayName = "NavbarMenuButton";

const NavbarMobileSubmenu = ({ children, title }: NavbarMenuProps) => {
  const id = useRef(uniqueId("navbar-menu-"));
  const elementRef = useRef<HTMLDivElement>();
  const popoverRef = useRef<HTMLDivElement>();
  // const history = useHistory();
  //   const [isOpen, setIsOpen] = useState(false);
  const { setIsMobileMenuOpen } = useContext(AppContext);
  const [isMobileSubmenuOpen, setIsMobileSubmenuOpen] = useState(false);

  const handlePopoverClick = () => {
    setIsMobileMenuOpen(false);
  };
  const handleMouseOver = (e: any) => {
    if (e.type === "mouseenter" || e.type === "click") {
      setIsMobileSubmenuOpen(true);
    } else if (
      e.type === "mouseleave" &&
      e.relatedTarget &&
      (e.relatedTarget === window ||
        (!elementRef.current?.contains(e.relatedTarget) &&
          !popoverRef.current?.contains(e.relatedTarget)))
    ) {
      setIsMobileSubmenuOpen(false);
    } else {
      setIsMobileMenuOpen(false);
      setIsMobileSubmenuOpen(false);
    }
  };

  return (
    <>
      {/* {!isMobileSubmenuOpen && ( */}
      <>
        <Button
          type={ButtonType.link}
          id={id.current}
          ref={elementRef}
          onMouseEnter={handleMouseOver}
          onClick={handleMouseOver}
          onMouseLeave={handleMouseOver}
          style={{
            color: "var(--tertiary)",
            fontWeight: 400,
            fontSize: "1.2rem",
            width: "100%"
          }}
        >
          {title}{" "}
          <span
            style={{
              padding: "0.1rem",
              marginTop: "-0.1rem",
              color: "var(--secondary)",
              fontSize: "1rem"
            }}
          >
            &#9660;
          </span>
        </Button>
        <Popover
          color={Color.tertiary}
          isOpen={isMobileSubmenuOpen}
          placement={Placement.bottomEnd}
          target={id.current}
          ref={popoverRef}
          onMouseLeave={handleMouseOver}
          onClick={handlePopoverClick}
        >
          <NavbarMenuList>{children}</NavbarMenuList>
        </Popover>
      </>
      {/* )} */}
    </>
  );
};

NavbarMobileSubmenu.Button = MenuButton;

export default NavbarMobileSubmenu;
