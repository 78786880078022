import React, { useContext } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Flex from "../../components/Flex";
import { AppContext } from "../../components/AppContext";
import StrCalculatorBody from "../StrCalculator/StrCalculatorBody";
import AnalyticsHeader from "./components/AnalyticsHeader";
import AnalyticsWrapper from "./components/AnalyticsWrapper";
import AddressAutoComplete from "../../components/AddressAutoComplete";
import Header from "../../components/Header";

const ResponsiveFlex = styled(Flex)`
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  // margin-bottom: 1rem;
  padding: 3rem 1rem;
  width: 100%;
  max-width: 95vw;
  margin-left: auto;
  margin-right: auto;
  display: block;

  @media (max-width: 800px) {
    // flex-direction: row;
    display: block;
  }
`;

const StyledHeader = styled(Header)`
  text-align: center;
  // font-size: 1.5rem;
  color: var(--tertiary);
  // margin-bottom: 1rem;
  margin: 0;
  padding: 0;
  width: 100%;
`;

export default function AnalyticsAirbnbCalculator() {
  const { market } = useParams<Record<string, string>>();
  const history = useHistory();
  const {
    mobileSize,
    prerenderHeight,
    setPrerenderHeight,
    setAddress: setAppContextAddress
  } = useContext(AppContext);
  const { setCalcLat, setCalcLng } = React.useContext(AppContext);
  const [address, setAddress] = React.useState("");
  const [calcState, setCalcState] = React.useState<any>(undefined);

  const onChange = ({ calcLat, calcLng, value }: any) => {
    setAddress(value);
    const searchParams = new URLSearchParams(history.location.search);
    searchParams.set("calcLat", calcLat);
    searchParams.set("calcLng", calcLng);
    history.replace({
      pathname: `/analytics/str-calculator/${encodeURIComponent(value)}`,
      search: searchParams.toString()
    });
  };

  React.useLayoutEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);
    const newAddress = searchParams.get("address");
    const calcLat = searchParams.get("calcLat");
    const calcLng = searchParams.get("calcLng");
    const beds = searchParams.get("beds");
    const baths = searchParams.get("baths");
    const accommodates = searchParams.get("accommodates");
    const price = searchParams.get("price");
    if (newAddress) {
      setAddress(newAddress);
    }
    if (calcLat) {
      setCalcLat(calcLat);
    }
    if (calcLng) {
      setCalcLng(calcLng);
    }

    if (beds || baths || accommodates || price) {
      setCalcState({
        beds,
        baths,
        accommodates,
        price
      });
    }
  }, []);

  React.useEffect(() => {
    if (market) {
      setAddress(decodeURIComponent(market));

      return () => {
        setAddress("");
        setAppContextAddress("");
        history.replace(history.location.pathname.replace(market, ""));
      };
    }

    return undefined;
  }, [history, market]);

  const pageTitle = `Analyzing Property: ${address}`;
  const pageDescription = `Analyzing property ${address}`;

  return (
    <AnalyticsWrapper style={{ minHeight: "100vh" }}>
      {address && (
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content={pageDescription} />
          {/* <meta property="og:image" content={listing.images[0]} /> */}
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDescription} />
          {/* <meta name="twitter:image" content={listing.images[0]} /> */}
          <link rel="canonical" href={window.location.href} />
        </Helmet>
      )}
      <AnalyticsHeader />
      <ResponsiveFlex>
        {/* {!mobileSize && (
          <StyledHeader size={1}>
            FREE AI-Powered Airbnb Calculator: Free, Simple, and Insightful
            Short-Term Rental Analysis
          </StyledHeader>
        )} */}

        <StyledHeader size={1}>FREE AI-Powered Airbnb Calculator</StyledHeader>

        <AddressAutoComplete
          name="address"
          onChange={onChange}
          placeholder="Enter an address to analyze ..."
          value={address}
          data-id="airbnb-calculator_address_input_field"
        />
      </ResponsiveFlex>
      <StrCalculatorBody address={address} calcState={calcState} />
    </AnalyticsWrapper>
  );
}
