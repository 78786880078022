import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Text from "./Text";

import "../styles/Footer.scss";

const NewsletterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--quaternary);
  padding: 1px;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
`;

const Input = styled.input`
  flex: 1;
  border: none;
  margin-right: -10px;
  padding: 10px;
  border-radius: 5px;
  background-color: var(--white);

  &:focus-visible {
    outline-color: var(--quaternary);
  }
`;

const Button = styled.button`
  background: var(--quaternary);
  color: var(--white);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;

  &:hover {
    background: var(--fourth);
  }
`;

const col1 = [
  { text: "Airbnb Investor Guides", link: "/analytics/investor-guides" },
  { text: "Airbnb Calculator", link: "/analytics/str-calculator" },
  { text: "Short-Term Rental Market Dashboards", link: "/analytics/overview" },
  { text: "Rental Regulations", link: "/analytics/rental-regulations" }
];

const col2 = [
  { text: "Short-Term Rental Real Estate Agents", link: "/real-estate-agents" },
  { text: "Property Managers", link: "/property-management" },
  { text: "Cost Segregation Specialists", link: "/cost-segregation" },
  { text: "Real Estate CPAs" },
  { text: "STR Lenders" }
];

const col3 = [
  {
    text: "Top Airbnbs Markets By Occupancy Rate",
    link: "/blog/top-airbnb-markets-by-the-occupancy-rate/"
  },
  {
    text: "Top Airbnb Markets By Gross Yield",
    link: "/blog/april-2023-best-markets-to-invest-in-airbnb-rentals-in-the-us-highest-gross-yield/"
  },
  {
    text: "Top Airbnb Markets in Florida",
    link: "/blog/best-short-term-rental-markets-in-florida-by-highest-yield/"
  },
  {
    text: "Top Mountain Towns By Gross Yield",
    link: "/blog/top-high-yield-mountain-towns-for-airbnb-rentals/"
  }
];

const NewsletterSignup = () => {
  const [email, setEmail] = useState("");
  const handleInputChange = (e: any) => {
    setEmail(e.target.value);
  };
  const handleSubmit = async () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(email)) {
      alert("Please use a correct email address!");
      return;
    }

    try {
      const requestOptions = {
        method: "POST",
        body: JSON.stringify({
          email,
          sourcePage: `${window.location.pathname}`
        })
      };
      const button = document.getElementById(
        "footer-newsletter"
      ) as HTMLButtonElement;
      if (button) button.disabled = true;
      await fetch(
        "https://cbehz6zbjl.execute-api.us-west-2.amazonaws.com/v1/contacts",
        requestOptions
      ).then(() => {
        button.innerHTML = "&#10003;";
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      // eslint-disable-next-line
      console.error("error scheduling consultation:", error);
      const button = document.getElementById(
        "footer-newsletter"
      ) as HTMLButtonElement;
      if (button) button.disabled = false;
    }
  };
  return (
    <NewsletterWrapper id="footer-input">
      <Input
        name="email"
        value={email}
        onChange={handleInputChange}
        type="email"
        placeholder="Your Email Address"
      />
      <Button
        id="footer-newsletter"
        data-id="join-newsletter-button"
        onClick={handleSubmit}
      >
        Join Our Newsletter
      </Button>
    </NewsletterWrapper>
  );
};

type ColumnItem = {
  text: string;
  link?: string;
};

// Define a type for the component props
type BulletsProps = {
  column: ColumnItem[];
};

const BulletItem = styled.li`
  background-image: url("/images/footer-bullet.png");
`;

const BulletLink = styled(Link)`
  cursor: pointer;
`;

const Bullets: React.FC<BulletsProps> = ({ column }) => {
  const onClick = () => {
    requestAnimationFrame(() => {
      window.scrollTo(0, 0);
    });
  };
  return (
    <ul className="bullet-list">
      {column.map(({ text, link }) => (
        <BulletItem key={`${text}-${link}`} className="list-item">
          {link ? (
            <BulletLink to={link} onClick={onClick}>
              {text}
            </BulletLink>
          ) : (
            <span>{text}</span>
          )}
        </BulletItem>
      ))}
    </ul>
  );
};

const IconLink = styled.a`
  display: contents;
`;

const FooterCallout = styled.p`
  display: block;
  font-size: 1rem;
  margin: 1rem auto;
  text-align: center;
`;

const FooterLink = styled.a`
  display: block;
  font-size: 1rem;
  margin: 0.25rem auto;
  text-align: center;
`;

const FooterParagraph = styled.p`
  max-width: 85vw;
  font-size: 0.8rem;
  margin: 1rem auto;
  text-align: center;
`;

const Footer = () => {
  return (
    <>
      <div id="footer">
        <div className="footer-row columns">
          <div id="first-column" className="footer-column">
            <div className="footer-column">
              <span className="title">RESOURCES</span>
              <Bullets column={col1} />
            </div>
          </div>
          <div id="second-column" className="footer-column">
            <div className="footer-column">
              <span className="title">SERVICE PROVIDERS</span>
              <Bullets column={col2} />
            </div>
          </div>
          <div id="third-column" className="footer-column">
            <div className="footer-column">
              <span className="title">BLOG</span>
              <Bullets column={col3} />
            </div>
          </div>
        </div>
        <div className="footer-row metadata">
          <div id="footer-copyright">
            <Text weight={400} style={{ padding: 0 }}>
              &copy; 2024 by GetChalet Inc.
            </Text>
          </div>
          <div className="footer-row" id="socials">
            <IconLink href="https://www.linkedin.com/company/getchalet/">
              <i
                className="fa-brands fa-linkedin fa-3x"
                style={{ color: "var(--fourth)" }}
                role="button"
                aria-label="Linked In icon link"
                tabIndex={0}
              />
            </IconLink>
            <IconLink href="https://www.facebook.com/getchalet">
              <i
                className="fa-brands fa-square-facebook fa-3x"
                style={{ color: "#525575" }}
                role="button"
                aria-label="Facebook icon link"
                tabIndex={0}
              />
            </IconLink>
            <IconLink href="https://www.instagram.com/chalet.hq/">
              <i
                className="fa-brands fa-instagram fa-3x"
                style={{ color: "var(--quaternary)" }}
                role="button"
                aria-label="Instagram icon link"
                tabIndex={0}
              />
            </IconLink>
          </div>
          <div id="footer-letter-block">
            <NewsletterSignup />
          </div>
        </div>
      </div>
      <FooterCallout>
        All Real Estate Services are offered through Chalet Realty.
      </FooterCallout>
      <FooterLink
        target="_blank"
        href="https://www.trec.texas.gov/sites/default/files/pdf-forms/CN%201-5_0.pdf"
        rel="noreferrer"
      >
        Texas Real Estate Commission Consumer Protection Notice
      </FooterLink>
      <FooterLink
        target="_blank"
        href="https://drive.google.com/file/d/1mffLVjAtQGTCbHnEUFv8_2DoSqrl09cc/view"
        rel="noreferrer"
      >
        Texas Real Estate Commission Information About Brokerage Services
      </FooterLink>
      <FooterLink
        target="_blank"
        href="https://d1e1jt2fj4r8r.cloudfront.net/938208c7-113c-4b76-96bb-f65cefa93252/pkR5n4vC6/trec.pdf"
        rel="noreferrer"
      >
        TREC Disclaimer
      </FooterLink>
      <FooterParagraph>
        The term &apos;Airbnb&apos; is used on our site in a colloquial sense,
        akin to saying &apos;I am going to Airbnb it.&apos; This usage is
        intended to refer broadly to the concept of short-term rentals and not
        specifically to the Airbnb platform or services. Airbnb, Inc. is not
        affiliated with Chalet, nor does it endorse or sponsor our services. We
        use the term in this generalized manner to easily convey the idea of
        participating in short-term rental activities, recognizing
        &apos;Airbnb&apos; as a term familiar to many in this context.
      </FooterParagraph>
      <FooterParagraph>
        GetChalet Inc. (Chalet) is not affiliated, associated, authorized,
        endorsed by, or in any way officially connected with Airbnb, Airbnb.com,
        or any of its subsidiaries or its affiliates. The official Airbnb
        website can be found at http://www.airbnb.com. The name “Airbnb” as well
        as related names, marks, emblems and images are registered trademarks of
        Airbnb, Inc.
      </FooterParagraph>
    </>
  );
};

export default Footer;
