import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Amplify, { Auth, Hub } from "aws-amplify";
import styled from "styled-components";
import { BrowserRouter } from "react-router-dom";
import FontFaceObserver from "fontfaceobserver";
import { v4 as uuidv4 } from "uuid";
import { toast, ToastContainer } from "react-toastify/dist/react-toastify.umd";
import Footer from "./components/Footer";
import useCookies from "./hooks/useCookies";
import awsconfig from "./aws-exports";
import { AppContext } from "./components/AppContext";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Routes from "./Routes";
import LoginModal from "./pages/modals/LoginModal";
import ContactUsModal from "./pages/modals/ContactUsModal";
import CostSegModal from "./pages/modals/CostSegModal";
import Layout from "./components/Layout";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Add this import
import ScrollToTop from "./components/ScrollToTop";
import CookieConsentModal from "./pages/modals/CookieConsentModal";
import RouterScrollToTop from "./components/RouterScrollToTop";
import "react-toastify/dist/ReactToastify.css";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [productionRedirectSignIn, localRedirectSignIn] =
  awsconfig.oauth.redirectSignIn.split(",");

const [productionRedirectSignOut, localRedirectSignOut] =
  awsconfig.oauth.redirectSignOut.split(",");

Amplify.configure({
  ...awsconfig,
  oauth: {
    ...awsconfig.oauth,
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : productionRedirectSignIn.replace(
          "https://getchalet.com",
          "https://www.getchalet.com"
        ),
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : productionRedirectSignOut.replace(
          "https://getchalet.com",
          "https://www.getchalet.com"
        )
  },
  ssr: true
});

const StyledApp = styled.div`
  max-width: 100vw;
  overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p {
    font-weight: 300;
  }
  h6 {
    font-weight: 200;
    font-size: 1rem;
    padding: 1rem;
  }
  @media (max-width: 800px) {
    .CookieConsent {
      display: block !important;
      // bottom: 2rem !important;
      div {
        margin: 1rem !important;
        flex: 0 !important;
      }
    }
  }
`;
function App() {
  const { setUser, user } = useContext(AppContext);
  const [formData, setFormData] = useState();
  const [anonUserId, setAnonUserId] = useState<string | null>(null);
  const { getCookie, setCookie, getAllCookies } = useCookies();

  const cookies: Record<string, string> = {};
  getAllCookies().forEach(([name, val]) => {
    cookies[name] = val;
  });
  useEffect(() => {
    console.log("just triggering a change");
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn": {
          setUser(data);
          const prevLocation = localStorage.getItem("prevLoc");

          if (prevLocation && prevLocation !== "undefined") {
            window.location.replace(prevLocation);
            localStorage.removeItem("prevLoc");
          }

          break;
        }
        case "signOut":
          setUser(undefined);
          break;
        case "customOAuthState": {
          setFormData(JSON.parse(data));
          const prevLocation = localStorage.getItem("prevLoc");

          if (prevLocation && prevLocation !== "undefined") {
            window.location.replace(prevLocation);
            localStorage.removeItem("prevLoc");
          }
          break;
        }
        default:
          break;
      }

      switch (event) {
        default: {
          // eslint-disable-next-line no-console
          // eslint-disable-next-line
          console.log("Hub", data);
          const prevLocation = localStorage.getItem("prevLoc");

          if (prevLocation && prevLocation !== "undefined") {
            window.location.replace(prevLocation);
            localStorage.removeItem("prevLoc");
          }
        }
      }
    });
    Auth.currentAuthenticatedUser()
      .then((newUser) => {
        // eslint-disable-next-line no-console
        // eslint-disable-next-line
        console.log({ user: newUser });
        setUser(newUser);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        // eslint-disable-next-line
      console.log(err);
      });
  }, []);

  useEffect(() => {
    const initializeAnonUser = async () => {
      try {
        // Check if user is authenticated
        const currentUser = await Auth.currentAuthenticatedUser();
        if (currentUser) {
          // For authenticated users, we don't need an anon_user_id
          console.log("currentUser in app", currentUser);
          setAnonUserId(null);
        } else {
          // For unauthenticated users, use or create an anon_user_id
          let existingAnonUserId = getCookie("anon_user_id");
          console.log("existingAnonUserId in app", existingAnonUserId);
          if (!existingAnonUserId) {
            existingAnonUserId = uuidv4();
            setCookie("anon_user_id", existingAnonUserId);
            // Initialize str_pred_query_count to 0
            setCookie("str_pred_query_count", "0");
          }
          setAnonUserId(existingAnonUserId);
        }
      } catch (error) {
        // If Auth.currentAuthenticatedUser() throws an error, the user is not authenticated
        let existingAnonUserId = getCookie("anon_user_id");
        if (!existingAnonUserId) {
          existingAnonUserId = uuidv4();
          setCookie("anon_user_id", existingAnonUserId);
          // Initialize str_pred_query_count to 0
          setCookie("str_pred_query_count", "0");
        }
        setAnonUserId(existingAnonUserId);
      }
    };
    initializeAnonUser();
  }, []);

  useEffect(() => {
    async function updateUser() {
      await Auth.updateUserAttributes(user, formData as any);
    }
    if (formData && user) {
      updateUser();
    }
  }, [formData, user]);

  useEffect(() => {
    const font = new FontFaceObserver("Source Sans Pro", {
      weight: 400
    });

    font.load().then(() => {
      document.body.classList.add("font-loaded");
    });
  }, []);

  return (
    <Layout>
      <StyledApp>
        <Helmet>
          <link rel="canonical" href="https://www.getchalet.com" />
          <title>
            Chalet | Short-Term &amp; Vacation Rental Investment Marketplace
          </title>
          <meta property="og:type" content="website" />
          <meta
            name="description"
            content="Chalet is the largest resource hub for everything short-term rentals.
              Find Airbnb Realtors and other STR Service providers. Invest in Short-Term (Airbnb) rentals with confidence"
          />
          <meta
            property="og:title"
            content="Chalet | Short-Term &amp; Vacation Rental Investment Marketplace"
          />
          <meta
            property="og:description"
            content="Chalet empowers investors with data insights and industry experts.
              Find Realtors, Property Managers, Airbnb laws, and other resources with our all-in-one platform."
          />
          <meta property="og:image" content="" />
          <meta property="og:url" content="https://www.getchalet.com" />
          <meta property="og:site_name" content="Chalet" />
        </Helmet>
        <ToastContainer position="bottom-right" hideProgressBar />
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <RouterScrollToTop />
          <Navbar />
          <Hero />
          <Routes />
          {/* <Delayed> */}
          {/* <div> */}
          {!("cookies" in cookies) && <CookieConsentModal />}
          {/* </div> */}
          {/* </Delayed> */}
          <ContactUsModal />
          <CostSegModal />
          <LoginModal />
          <Footer />
        </BrowserRouter>
        {/* <ScrollToTop /> */}
      </StyledApp>
    </Layout>
  );
}

export default App;
