import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { AppContext } from "../components/AppContext";
import Header from "../components/Header";
import Image from "../components/Image";
import Section from "../components/Section";
import Text from "../components/Text";
import SplitView from "../components/SplitView";
import { Color } from "../types";
import Button from "../components/Button";
import SectionFooter from "../components/SectionFooter";
import Group from "../components/Group";
import ContactUsModal from "./modals/ContactUsModal";

const Buy = () => {
  const { mobileSize } = useContext(AppContext);

  const handleFindARealtorClick = () => {
    window.location.href =
      "https://form.typeform.com/to/lMhk4Fej#market_of_interest=Not Specified&source_page=buy";
  };
  const handle1031ExchangeClick = () => {
    // history.push("/1031-exchange");
    window.location.href =
      "https://form.typeform.com/to/lMhk4Fej#market_of_interest=Not Specified&source_page=buy";
  };

  const handleContactUsClick = () => {
    window.location.href =
      "https://form.typeform.com/to/lMhk4Fej#market_of_interest=Not Specified&source_page=buy";
  };

  return (
    <div style={{ paddingTop: "208px" }}>
      <Helmet>
        <link rel="canonical" href="https://www.getchalet.com/buy" />
        <title>Buy Properties With Best ROI With Chalet Partners</title>
        <meta property="og:type" content="website" />
        <meta
          name="description"
          content="Maximize your return on investment.
          Trusted Airbnb Realtors with short-term rental market expertise
          to get the best ROI. 1031 Exchange Experts for Airbnb Rentals"
        />
        <meta
          property="og:title"
          content="Buy Properties With Best ROI With Chalet Partners"
        />
        <meta
          property="og:description"
          content="Maximize your return on investment.
          Trusted Airbnb Realtors with short-term rental market expertise
          to get the best ROI. 1031 Exchange Experts for Airbnb Rentals"
        />
        <meta property="og:image" content="" />
        <meta property="og:url" content="https://www.getchalet.com/buy" />
        <meta property="og:site_name" content="Chalet" />
      </Helmet>
      <Header
        size={1}
        // font={Font.Pacifico}
        mobileSize={mobileSize}
      >
        Invest With Airbnb Real Estate Experts
      </Header>
      <Section
        style={{
          background: "var(--white)",
          boxShadow: mobileSize ? "inherit" : "none",
          borderRadius: mobileSize ? "none" : "0.5rem 0.5rem 0 0",
          overflow: "inherit"
        }}
      >
        <Image
          src={`${process.env.PUBLIC_URL}/images/invest-with-experts.webp`}
          alt="Buy Airbnb Rentals With Chalet"
          style={{
            padding: mobileSize ? "2rem" : "0rem",
            position: "absolute",
            top: "-9rem",
            right: "2rem",
            // borderRadius: "0.25rem",
            maxWidth: mobileSize ? "100%" : "250px",
            width: "100%",
            display: mobileSize ? "none" : "block"
          }}
          $mobileSize={mobileSize}
        />
        <Text
          block
          align="left"
          color={Color.tertiary}
          lineHeight="2.5rem"
          // style={{ color: "var(--tertiary)" }}
        >
          Why work with a real estate generalist when you can work with an
          Airbnb investment specialist? Chalet partner realtors are experts on
          vacation rentals and their local real estate markets. Our realtors can
          point you to the properties that meet your investment parameters with
          the highest ROI potential.
        </Text>
        {/* <HR $color={Color.primary} /> */}
        <Text block align="left" lineHeight="2.5rem" color={Color.tertiary}>
          And the best part is you will have an Airbnb real estate expert on
          your team at NO EXTRA COST.
        </Text>
        <Image
          src={`${process.env.PUBLIC_URL}/images/invest-with-experts.webp`}
          alt="Buy Airbnb Rentals With Chalet"
          style={{
            padding: "0rem",
            // position: "absolute",
            // top: "-9rem",
            right: "2rem",
            // borderRadius: "0.25rem",
            maxWidth: mobileSize ? "100%" : "250px",
            width: "100%",
            display: mobileSize ? "block" : "none"
          }}
          $mobileSize={mobileSize}
        />
        <SectionFooter
          $mobileSize={mobileSize}
          style={{ paddingBottom: mobileSize ? "3rem" : "inherit" }}
        >
          <Button
            block
            color={Color.quinary}
            style={{ [mobileSize ? "marginTop" : "marginLeft"]: "1rem" }}
            onClick={handleFindARealtorClick}
            data-id="buy-cta1_find-realtor_button"
          >
            Find a Realtor
          </Button>
        </SectionFooter>
      </Section>
      <Group>
        <Section
          style={{
            boxShadow: mobileSize ? "none" : "inherit"
          }}
        >
          <Header size={2}>Looking To Sell Your Short-Term Rental?</Header>
          <SplitView
            split="2:3"
            style={{
              alignItems: "start",
              margin: "0 auto"
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gridRowStart: mobileSize ? "1" : "inherit"
              }}
            >
              <Image
                src={`${process.env.PUBLIC_URL}/images/sell.webp`}
                alt="Sell your Airbnb rental with Chalet"
                style={{
                  borderRadius: "0.25rem",
                  maxWidth: mobileSize ? "100%" : "300px",
                  width: "100%"
                }}
                $mobileSize={mobileSize}
              />
            </div>
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit"
              }}
            >
              <Text
                block
                align="left"

                // style={{ lineHeight: "2rem" }}
                // lineHeight="4.5rem"
              >
                A savvy seller understands that investment home buyers are
                primarily focused on finding the property that will bring the
                highest ROI.
              </Text>
              <Text block align="left">
                Attracting the right Airbnb investor requires a unique marketing
                strategy, very different from selling a primary residence. Our
                Chalet partner realtors know how to effectively promote your
                short-term rental to the right buyers.
              </Text>
              <Text
                block
                align="left"

                // lineHeight="4.5rem"
              >
                And the best part is you will have an Airbnb real estate expert
                on your team at NO EXTRA COST.
              </Text>
              <Button
                block
                style={{ margin: "0rem auto" }}
                // color={Color.tertiary}
                onClick={handleContactUsClick}
                data-id="buy-cta2_sell_button"
              >
                Help me sell my property
              </Button>
            </div>
          </SplitView>
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group>
      <Group
        style={{
          background:
            "radial-gradient(4291.36% 46161.46% at 2886.22% -7976.07%, #BC6A81 36.46%, #695979 66.15%, #FB929D 71.35%)"
        }}
      >
        <Section
          style={{
            boxShadow: "none",
            background: "none"
          }}
        >
          <Header size={2} color={Color.white}>
            1031 Exchange
          </Header>
          <SplitView split="3:2" style={{ alignItems: "start" }}>
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit"
              }}
            >
              <Text
                block
                align="left"
                style={{ lineHeight: "2.5rem" }}
                color={Color.white}
              >
                Considering buying a 1031 exchange short-term rental? We are
                your team of 1031 exchange short-term rental experts. We
                understand the unique nuances of a 1031 exchange transaction.
              </Text>
              <Text block align="left" color={Color.white}>
                Furthermore, we will help you find the investment market best
                for your needs and refer you to local vendors who also
                understand 1031 regulations. We also understand the strict
                timelines associated with 1031 exchanges and will do our best to
                quickly launch your investment home-buying journey in the right
                direction.
              </Text>
              <Button
                block
                style={{
                  margin: "0rem auto",
                  color: "var(--quinary)",
                  display: mobileSize ? "none" : "block"
                }}
                color={Color.white}
                onClick={handle1031ExchangeClick}
                data-id="buy-cta3_1031exchange_button"
              >
                Get started
              </Button>
            </div>
            <Image
              src={`${process.env.PUBLIC_URL}/images/1031-exchange.webp`}
              alt="1031 Exchange your airbnb rental with Chalet"
              $mobileSize={mobileSize}
              style={{
                gridRowStart: mobileSize ? "1" : "inherit",

                maxWidth: mobileSize ? "100vw" : "400px"
              }}
            />
          </SplitView>
          <SectionFooter $mobileSize={mobileSize}>
            <Button
              block
              style={{
                margin: "0rem auto",
                color: "var(--quinary)",
                display: mobileSize ? "block" : "none"
              }}
              color={Color.white}
              onClick={handle1031ExchangeClick}
              data-id="buy-cta3_1031exchange_button"
            >
              Get started
            </Button>
          </SectionFooter>
        </Section>
      </Group>
      <Group
        style={{
          paddingBottom: "0rem",
          paddingTop: "0rem"
        }}
      >
        <Section
          style={{
            boxShadow: "none",
            paddingBottom: mobileSize ? "12rem" : "10rem",
            background: "none"
          }}
        >
          <Header size={2}>Top Locations</Header>

          <SplitView split="2:3" style={{ alignItems: "start" }}>
            <div>
              <Image
                src={`${process.env.PUBLIC_URL}/images/our-results.webp`}
                alt="Top locations for Airbnb Investment"
                $mobileSize={mobileSize}
                style={{
                  // padding: "0rem",
                  // position: "absolute",
                  // left: 0,
                  // bottom: mobileSize ? "-8rem" : "-3rem",
                  maxWidth: mobileSize ? "400px" : "600px",
                  display: "block",
                  borderRadius: "0.25rem"
                }}
              />
            </div>
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit"
              }}
            >
              <Text block align="left">
                Chalet has an established realtor network in top Airbnb-friendly
                markets across the US.
              </Text>
              <Text block align="left">
                Don&apos;t see your Airbnb market of choice? No problem - we
                will still connect you to a local short-term rental real estate
                expert. And again, we will do it at NO EXTRA COST.
              </Text>
              <Button
                block
                style={{ margin: "2rem auto" }}
                // color={Color.primary}
                onClick={handleContactUsClick}
                data-id="buy-cta4-get-started_button"
              >
                Get Started
              </Button>
            </div>
          </SplitView>
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group>
      <Group
        style={{
          background:
            "linear-gradient(1.82deg, #FEE3E7 -89.78%, #FB929D 102.71%)"
        }}
      >
        <Section
          style={{
            boxShadow: "none",
            background: "none"
          }}
        >
          <Header size={2} color={Color.white}>
            Airbnb Investment Consulting
          </Header>
          <SplitView split="3:2" style={{ alignItems: "start" }}>
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit",
                paddingBottom: mobileSize ? "inherit" : "0",
                paddingTop: mobileSize ? "inherit" : "0"
              }}
            >
              <Text block align="left" color={Color.black}>
                Not sure which Airbnb investment market is right for you? We can
                help.
              </Text>
              <Text block align="left" color={Color.black}>
                We help you analyze, narrow down, and pick the right short-term
                rental market specific to your individual investment goals. From
                there, we dig deeper into the Airbnb data to identify your
                chosen market&apos;s top short-term rental neighborhoods. We
                also analyze the local competition, AKA the top Airbnb listings,
                with the goal of helping you understand what home features drive
                success. And once you identify your top properties, we analyze
                them based on your budget/attributes to identify each
                property&apos;s projected return on investment.
              </Text>
              <Text block align="left" color={Color.black}>
                In short, with this service, we aim to lead you to the highest
                ROI property.
              </Text>
              <Button
                block
                style={{
                  margin: "2rem auto",
                  display: mobileSize ? "none" : "block"
                }}
                color={Color.primary}
                onClick={handleContactUsClick}
                data-id="buy-cta5_1031exchange_button"
              >
                Invest Now
              </Button>
            </div>
            <Image
              src={`${process.env.PUBLIC_URL}/images/chalet-banner.webp`}
              alt="Top locations for Airbnb Investment"
              $mobileSize={mobileSize}
              style={{
                padding: "0rem",
                // aspectRatio: "4/3",
                gridRowStart: mobileSize ? "1" : "inherit",
                display: "block",
                maxWidth: mobileSize ? "70vw" : "500px",
                borderRadius: "0.6rem"
              }}
            />
          </SplitView>

          <SectionFooter $mobileSize={mobileSize}>
            <Button
              block
              style={{
                margin: "2rem auto",
                display: mobileSize ? "block" : "none"
              }}
              color={Color.primary}
              onClick={handleContactUsClick}
              data-id="buy-cta5_1031exchange_button"
            >
              Invest Now
            </Button>
          </SectionFooter>
        </Section>
      </Group>
      <Group style={{ marginTop: "4rem" }}>
        <Section
          style={{
            // maxWidth: mobileSize ? "100%" : "85vw",
            // padding: mobileSize ? "0rem" : "1rem",
            boxShadow: "none",
            border: "2px solid",
            borderImageSource:
              "linear-gradient(269.89deg, #D67C95 -0.01%, #695979 99.8%)",
            // borderWidth: "2px",
            // borderStyle: "solid",
            // background: "var(--white)",
            position: "relative",
            overflow: "hidden"
          }}
        >
          <Header
            style={{
              background:
                "linear-gradient(269.89deg, #D67C95 -0.01%, #695979 99.8%)",
              // backgroundClip: "text",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              backgroundClip: "text"
            }}
            size={2}
          >
            Become a Chalet Partner Realtor
          </Header>
          <SplitView split="3:2" style={{ alignItems: "start" }}>
            <div
              style={{
                paddingTop: 0,
                gridRowStart: mobileSize ? "2" : "inherit"
              }}
            >
              <Text
                block
                align="left"
                style={{
                  background:
                    "linear-gradient(269.89deg, #D67C95 -0.01%, #695979 99.8%)",

                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text"
                }}
              >
                Are you a top agent who understands the nuances of short-term
                rentals in your local market?
              </Text>
              <Text
                block
                align="left"
                style={{
                  background:
                    "linear-gradient(269.89deg, #D67C95 -0.01%, #695979 99.8%)",

                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text"
                }}
              >
                Let&apos;s connect! Reach out to learn more about our Chalet
                referral services.
              </Text>
              <Button
                block
                style={{ margin: "2rem auto" }}
                // color={Color.primary}
                onClick={handleContactUsClick}
                data-id="buy-cta6_contact-us_button"
              >
                Learn More
              </Button>
            </div>
            <Image
              src={`${process.env.PUBLIC_URL}/images/partner-realtor.webp`}
              alt="Become top Chalet Airbnb Partner realtor"
              $mobileSize={mobileSize}
              style={{
                gridRowStart: mobileSize ? "1" : "inherit",
                maxWidth: mobileSize ? "100%" : "400px",
                display: "block",
                borderRadius: "0.25rem"
              }}
            />
          </SplitView>
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group>
      {/* <Group>
        <Section>
          <Header size={2}>
            Get Higher Yields Through Airbnb Rentals With 1031 Exchange
          </Header>
          <SplitView split="2:3">
            <div>
              <Text
                block
                
                align="center"
                
                style={{ lineHeight: "2rem" }}
                // lineHeight="4.5rem"
              >
                A 1031 exchange allows investors to defer all capital gain taxes
                when selling a property if they reinvest the proceeds in a new
                property or portfolio of properties, including Airbnb Rentals in
                Fort Lauderdale, Florida.
              </Text>

              <HR $color={Color.quinary} />
              <Text
                block
                
                align="center"
                weight={400}
                style={{ lineHeight: "2rem" }}
                // lineHeight="4.5rem"
              >
                Work with Chalet, your team of short-term rental experts and
                Airbnb specialists to help you find your next 1031 exchange
                property.
              </Text>

              <HR $color={Color.quinary} />
              <Text
                block
                
                align="center"
                
                style={{ lineHeight: "2rem" }}
                // lineHeight="4.5rem"
              >
                New to 1031 exchanges? No problem, we are here to connect you
                with the right resources to decipher if this investment strategy
                works for you.
              </Text>
            </div>
            <Image
              src={`${process.env.PUBLIC_URL}/images/1031exchange.webp`}
              alt="Need help launching your Airbnb Business?"
              $mobileSize={mobileSize}
              style={{
                gridRowStart: mobileSize ? "1" : "inherit",

                borderRadius: "0.25rem"
              }}
            />
          </SplitView>
          <SectionFooter $mobileSize={mobileSize}>
            <Button
              block
              color={Color.tertiary}
              onClick={handle1031ExchangeClick}
              data-id="chalet-locations-fort-lauderdale_1031-excange_button"
            >
              Get Started
            </Button>
          </SectionFooter>
        </Section>
      </Group> */}
      <ContactUsModal />
    </div>
  );
};

export default Buy;
