import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { PopupButton } from "react-calendly";
import Header from "../components/Header";
import HR from "../components/HR";
import Text from "../components/Text";
import Field from "../components/Field";
import { OptionProp } from "../components/Select";
import Image from "../components/Image";

import useCookies from "../hooks/useCookies";

import Button from "../components/Button";
import { Color } from "../types";
import { AppContext } from "../components/AppContext";
import Flex from "../components/Flex";
import SplitView from "../components/SplitView";
import Section from "../components/Section";
import isValidEmail from "../utils/validation"; // Add this import

function toTitleCase(str: string) {
  return str
    .toLowerCase()
    .replace("-", " ")
    .split(" ")
    .map((word: string) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

const defaultForm = {
  name: "",
  // phone: "",
  email: "",
  consent: true
};

const DownloadReport = () => {
  const { getAllCookies } = useCookies();
  const { mobileSize } = useContext(AppContext);
  const [isContactUs, setContactUs] = useState(false);
  const [thankYou, setThankYou] = useState(false);
  const [anonUserId, setAnonUserId] = useState<string | null>(null);

  // console.log("history", history);
  function getMarketFromSlug(marketSlug: string) {
    // let marketFromSlug = '
    // eslint-disable-next-line
    const regularPlogPostSlug = (marketSlug.split('-').length - 1) > 3;
    // console.log("Is it regular blog post slug?:", regularPlogPostSlug);
    // console.log("makret slug beffore removal of -", marketSlug);
    let marketFromSlug = marketSlug
      ? toTitleCase(marketSlug.replace("-", " "))
      : "";
    // eslint-disable-next-line
    console.log("marketFromSlug before transform", marketFromSlug);
    if (marketSlug === "other" || regularPlogPostSlug) {
      marketFromSlug = "Other";
    } else {
      marketFromSlug = marketFromSlug.replace(
        marketFromSlug.slice(-3),
        `, ${marketFromSlug.slice(-2).toUpperCase()}`
      );
      // eslint-disable-next-line
    console.log("its not three/four words", marketFromSlug);
    }
    // eslint-disable-next-line
    console.log("its three/fourds word slug after transform", marketFromSlug);
    return marketFromSlug;
  }
  const { search } = useLocation();
  // eslint-disable-next-line
    console.log("Search", search);

  const ConsentLabel = () => {
    return (
      <div
        style={{
          fontWeight: 300,
          fontSize: "0.7rem",
          marginTop: "-0.9rem",
          marginBottom: "2rem"
        }}
      >
        By submitting this form, you agree to receive subsequent emails and
        third-party marketing communications from Chalet pursuant to our{" "}
        <Link
          to="/privacy-policy"
          target="_blank"
          style={{
            cursor: "pointer",
            display: "inline-block",
            textDecoration: "underline"
          }}
        >
          Privacy Policy
        </Link>
        , which you may opt out of, or unsubscribe from, at any time.
      </div>
    );
  };

  const marketSearchQuery = search ? search.split("=")[1].split("&")[0] : "";
  const postTypeQuery = search ? search.split("=")[2] : "";
  const marketOfInterest = getMarketFromSlug(marketSearchQuery);

  const [errors, setErrors] = useState<any>({
    name: false,
    // phone: false,
    email: false,
    notes: false
  });
  const cookies: Record<string, string> = {};

  getAllCookies().forEach(([cookieName, val]) => {
    cookies[cookieName] = val;
  });
  const [form, setForm] = useState(defaultForm);
  const { name, email, consent } = form;

  useEffect(() => {
    const storedAnonUserId = localStorage.getItem("anon_user_id");
    setAnonUserId(storedAnonUserId);
  }, []);

  useEffect(() => {
    async function addContact() {
      // eslint-disable-next-line
    console.log("name", name);
      // eslint-disable-next-line
    console.log("Consent", consent);
      try {
        const requestOptions = {
          method: "POST",
          // headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name,
            email,
            // phone,
            downloadReport: true,
            market: marketOfInterest,
            post_type: postTypeQuery,
            marketSlug: marketSearchQuery,
            consent,
            ...cookies,
            sourcePage: postTypeQuery,
            anon_user_id: anonUserId
          })
        };
        // const apiData = await API.post("customers", "/contacts", data);
        await fetch(
          "https://cbehz6zbjl.execute-api.us-west-2.amazonaws.com/v1/contacts",
          requestOptions
        );
        setThankYou(true);
      } catch (error) {
        // eslint-disable-next-line no-console
        // eslint-disable-next-line
    console.log("error scheduling consultation:", error);
      }
      setContactUs(false);
    }
    if (isContactUs) {
      setContactUs(true);
      addContact();
    }
  }, [isContactUs, form]);

  const allowContactUs = () => {
    const newErrors = { ...errors };
    let isValid = true;

    // Check if all fields are filled and email is valid
    Object.entries(form).forEach(
      ([formName, value]: [string, string | boolean]) => {
        if (
          formName !== "password" &&
          formName !== "confirmpassword" &&
          formName !== "verificationcode" &&
          formName !== "other"
        ) {
          if (formName === "consent") {
            newErrors.consent = !value;
            isValid = isValid && (value as boolean);
          } else if (formName === "email") {
            const isValidEmailFormat = isValidEmail(value as string);
            newErrors.email = !isValidEmailFormat;
            isValid = isValid && isValidEmailFormat;
          } else {
            newErrors[formName] = value === "";
            isValid = isValid && value !== "";
          }
        }
      }
    );

    setErrors(newErrors);
    return isValid;
  };

  const onChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.persist();
    const { name: n, value } = e.target as HTMLInputElement;
    // eslint-disable-next-line
    console.log("On change name", n);
    // eslint-disable-next-line
    console.log("On change value", value);
    let inputName = n;
    let inputValue = value;
    if (!inputName) {
      inputName = (e as any).name;
      inputValue = ((e as any).value as OptionProp).label as string;
    }
    setForm((prevState) => ({
      ...prevState,
      [inputName]: inputValue
    }));
  };
  return (
    <div
      style={{ paddingTop: mobileSize ? "120px" : "100px", background: "none" }}
    >
      <Helmet>
        <link
          rel="canonical"
          href="https://www.getchalet.com/download-report"
        />
        <title>Unlock Airbnb Success: Free Market Comparison Guide</title>
        <meta property="og:type" content="website" />
        <meta
          name="description"
          content="Discover top 200 Airbnb markets in the US with our FREE guide. Analyze appreciation rates, prices, yields, and more for smarter investments. Download now!"
        />
        <meta
          property="og:title"
          content="Unlock Airbnb Success: Free Market Comparison Guide"
        />
        <meta
          property="og:description"
          content="Discover top 200 Airbnb markets in the US with our FREE guide. Analyze appreciation rates, prices, yields, and more for smarter investments. Download now!"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.getchalet.com/download-report"
        />
        <meta property="og:site_name" content="Chalet" />
      </Helmet>
      <Section
        style={{
          background: mobileSize ? "var(--white)" : "none",
          boxShadow: "none",
          borderRadius: 0,
          maxWidth: "100%",
          padding: mobileSize ? "2rem" : "2rem",
          margin: "2rem auto 0"
        }}
      >
        <Header size={1} style={{ color: "var(--tertiary)", fontWeight: 500 }}>
          {" "}
          Airbnb Rentals Market Report - Top 200 US Markets
        </Header>

        <SplitView
          style={{
            padding: mobileSize ? "1rem" : "initial",
            // paddingTop: "1rem",
            // backgroundColor: "rgba(255,255,255, 0.7)",
            // background: "url(../images/markets-bg.webp)",
            borderRadius: "0.25rem",

            maxWidth: mobileSize ? "90vw" : "1200px",
            margin: "1rem auto"
          }}
          split="2:3"
        >
          {!thankYou && (
            <div style={{ paddingBottom: "1rem" }}>
              <>
                <div
                  style={{ width: mobileSize ? "calc(100% - 0rem)" : "100%" }}
                >
                  <Field
                    name="name"
                    placeholder="Name"
                    type="text"
                    value={name}
                    invalid={errors.name}
                    onChange={onChange}
                  />
                  {/* <Field
                    name="phone"
                    placeholder="Phone"
                    type="text"
                    value={phone}
                    invalid={errors.phone}
                    onChange={onChange}
                  /> */}
                  <Field
                    name="email"
                    placeholder="Email"
                    type="email"
                    value={email}
                    invalid={errors.email}
                    onChange={onChange}
                  />
                  <ConsentLabel />
                  {Object.values(errors).some((value) => value) && (
                    <Text
                      color={Color.danger}
                      style={{
                        display: "block",
                        padding: "0.5rem",
                        textAlign: "center",
                        width: "100%"
                      }}
                    >
                      Fields{" "}
                      {Object.entries(errors)
                        .map(([key, value]) => (value ? key : undefined))
                        .filter((str) => str !== undefined)
                        .join(", ")}{" "}
                      are missing values
                    </Text>
                  )}
                  <Button
                    isLoading={isContactUs}
                    style={{
                      marginBottom: "1rem",
                      padding: "1rem",
                      width: "100%",
                      background: "var(--tertiary)"
                    }}
                    onClick={() => allowContactUs() && setContactUs(true)}
                    data-id="modal_contact_us_message_us_button"
                  >
                    Download Now
                  </Button>
                </div>
              </>
            </div>
          )}
          {thankYou && (
            <Flex
              style={{
                background: "#fff",
                // alignItems: "stretch",
                flexDirection: "column",
                padding: mobileSize ? "0.5rem" : "3rem",
                textAlign: "center"
                // minHeight: "70vh"
              }}
            >
              <div style={{ padding: "1rem" }}>
                <Text block style={{ fontSize: "2rem", textAlign: "center" }}>
                  <Text
                    // block
                    weight={400}
                    color={Color.quinary}
                    style={{ fontSize: "2rem", textAlign: "center" }}
                  >
                    Woo hoo!&nbsp;
                  </Text>
                  Airbnb market comparison guide will be sent to your inbox
                </Text>
                <HR />
                <Text block style={{ fontSize: "2rem", textAlign: "center" }}>
                  We are here to help you create wealth with short-term rentals.
                  The next step would be to schedule a FREE 30 minute call with
                  our short-term rental expert.
                </Text>
              </div>

              <PopupButton
                className="calendly-button"
                rootElement={document.getElementById("root")!}
                text="Talk to STR Expert"
                styles={{
                  backgroundColor: "var(--quinary)",
                  textTransform: "uppercase",
                  color: "var(--white)",
                  padding: "1rem",
                  boxShadow: "none",
                  fontSize: "1.5rem",
                  borderRadius: "0.25rem",
                  border: "none",
                  cursor: "pointer"
                }}
                pageSettings={{
                  backgroundColor: "ffffff",
                  hideEventTypeDetails: false,
                  hideLandingPageDetails: false,
                  primaryColor: "00a2ff",
                  textColor: "4d5055"
                }}
                prefill={{
                  name,
                  email
                }}
                url="https://calendly.com/chalet-ashley/chalet-call-intro?hide_landing_page_details=1&utm_source=getchalet.com&utm_medium=website&utm_campaign=ThankYouPage&utm_term=DownloadReport"
              />
            </Flex>
          )}
          <div>
            {/* {!thankYou && ( */}
            <>
              <Image
                src={`${process.env.PUBLIC_URL}/images/chalet_markets_2024.webp`}
                alt="Market Report"
                style={{
                  padding: "0.5rem",
                  maxWidth: "100%",
                  boxShadow: "rgb(219, 0, 95) 8px 8px 0px 0px",
                  border: "1px solid var(--tertiary)"
                }}
                $mobileSize={mobileSize}
              />
              <Text
                block
                size="1.2rem"
                align="center"
                weight={300}
                // style={{ lineHeight: "2.7rem" }}
                // lineHeight="3.5rem"
              >
                Annual revenue, average daily rate, gross yield, occupancy
                rates, cap rates and a lot more...
              </Text>
            </>
            {/* )} */}
          </div>
        </SplitView>
      </Section>
      <Section style={{ boxShadow: "none", maxWidth: "100%" }}>
        <Text block>
          Welcome to the future of Airbnb investments! If you&apos;re serious
          about making informed decisions and capitalizing on the booming
          short-term rental market, our Market Comparison Guide is your secret
          weapon. Dive into an Excel sheet packed with data on the top 150
          Airbnb markets in the United States, and unlock the potential for
          higher profits.
        </Text>
        <HR />
        <Header size={3} style={{ textAlign: "left" }}>
          What Our Market Comparison Guide Offers:
        </Header>
        <Text block>
          Our guide goes beyond traditional real estate data. It&apos;s your key
          to understanding the intricate details of each market, helping you
          make strategic moves in the ever-evolving world of Airbnb hosting.
        </Text>
        <HR />
        <Header size={3} style={{ textAlign: "left" }}>
          <Text>In-Depth Insights:</Text> Our report covers crucial metrics for
          each market, such as:
        </Header>
        <ul>
          <li style={{ fontWeight: 300, fontSize: "1.3rem" }}>
            Appreciation Rate: Track property value trends
          </li>
          <li style={{ fontWeight: 300, fontSize: "1.3rem" }}>
            Median Home Prices: Gauge affordability and potential returns
          </li>
          <li style={{ fontWeight: 300, fontSize: "1.3rem" }}>
            Gross Yields: Calculate potential rental income
          </li>
          <li style={{ fontWeight: 300, fontSize: "1.3rem" }}>
            Cap Rates: Assess investment profitability
          </li>
          <li style={{ fontWeight: 300, fontSize: "1.3rem" }}>
            Annual Revenue: Predict your yearly earnings
          </li>
          <li style={{ fontWeight: 300, fontSize: "1.3rem" }}>
            ADR (Average Daily Rate): Optimize pricing strategies
          </li>
          <li style={{ fontWeight: 300, fontSize: "1.3rem" }}>
            Occupancy Rate: Know your property&apos;s booking potential
          </li>
          <li style={{ fontWeight: 300, fontSize: "1.3rem" }}>
            Redfin Competitor Score: Measure market competitiveness
          </li>
          <li style={{ fontWeight: 300, fontSize: "1.3rem" }}>
            Redfin Days on the Market: Evaluate property sale speed
          </li>
        </ul>
        <Text block>
          <Text style={{ fontSize: "1.5rem", color: "var(--quinary)" }}>
            top 150 Markets:
          </Text>{" "}
          We&apos;ve carefully selected and analyzed the most promising Airbnb
          markets across the United States. You&apos;re not limited to a few
          options; you have the flexibility to explore and choose from a wide
          variety of locations.
        </Text>
        <Text block>
          <Text style={{ fontSize: "1.5rem", color: "var(--quinary)" }}>
            Smarter Decisions:
          </Text>{" "}
          Armed with our insights, you can confidently make decisions that align
          with your investment goals and risk tolerance, paving the way for
          smarter, more profitable choices.
        </Text>
        <HR />
        <Header size={3} style={{ textAlign: "left" }}>
          Why Our Guide Stands Out:
        </Header>
        <Text block>
          <Text style={{ fontSize: "1.5rem", color: "var(--quinary)" }}>
            User-Friendly Format:
          </Text>{" "}
          The report is designed to be user-friendly, making data analysis and
          market comparisons a breeze, so you can spend less time crunching
          numbers and more time making decisions.
        </Text>
        <Text block>
          <Text style={{ fontSize: "1.5rem", color: "var(--quinary)" }}>
            Absolutely Free:
          </Text>{" "}
          Yes, it&apos;s true! Our Market Comparison Guide comes at no cost to
          you. You get a competitive edge without opening your wallet.
        </Text>
        <Text block>
          <Text style={{ fontSize: "1.5rem", color: "var(--quinary)" }}>
            Expert Guidance:
          </Text>{" "}
          Behind this guide is a team of seasoned real estate and Airbnb market
          experts who provide valuable insights to help you navigate the complex
          landscape of short-term rentals.
        </Text>
        <HR />
        <Header size={3} style={{ textAlign: "left" }}>
          How to Access Your Free Market Comparison Guide:
        </Header>
        <Text block>
          <Text style={{ fontSize: "1.5rem", color: "var(--quinary)" }}>
            1. Click &quot;Download Now&quot;:
          </Text>{" "}
          With just a click, you&apos;ll have instant access to a world of
          Airbnb investment opportunities.
        </Text>
        <Text block>
          <Text style={{ fontSize: "1.5rem", color: "var(--quinary)" }}>
            2. Uncover Potential:
          </Text>{" "}
          Explore the top 150 Airbnb markets in the United States and unveil the
          hidden gems waiting for you.
        </Text>
        <Text block>
          <Text style={{ fontSize: "1.5rem", color: "var(--quinary)" }}>
            3. Stay Ahead:
          </Text>{" "}
          Keep your Airbnb business one step ahead of the competition with our
          expert insights.
        </Text>
        <Text block>
          <Text style={{ fontSize: "1.5rem", color: "var(--quinary)" }}>
            4. Financial Freedom Awaits:
          </Text>{" "}
          Turn your short-term rental properties into a lucrative source of
          income and start your journey towards financial freedom.
        </Text>
        <HR />
        {/* <Text>
          Don&apos;t miss out on this chance to elevate your Airbnb investments.
          Download our Free Market Comparison Guide now and open doors to new
          Airbnb success!
        </Text> */}
        <Header size={2} style={{ color: "var(--tertiary)" }}>
          Invest with confidence. Prosper with knowledge
        </Header>
      </Section>
      <Text
        block
        style={{
          fontSize: "0.9rem",
          color: "var(--quaternary)",
          margin: "0 auto",
          textAlign: "center",
          fontStyle: "italic"
        }}
      >
        [Disclaimer: This guide serves informational purposes only and should
        not be considered as financial or investment advice. Always conduct your
        research and seek professional guidance before making investment
        decisions.]
      </Text>
    </div>
  );
};

export default DownloadReport;
