import React from "react";
import styled, { css } from "styled-components";
import { Color } from "../types";
import { Font } from "./Text";

export interface HeaderProps extends React.HTMLAttributes<HTMLHeadingElement> {
  color?: Color;
  font?: Font;
  size?: 1 | 2 | 3 | 4 | 5 | 6;
  subtitle?: boolean;
  mobileSize?: boolean;
}

const sizes: Record<string, string> = {
  "1": "2.4rem",
  "2": "2.0rem",
  "3": "1.75rem",
  "4": "1.4rem",
  "5": "1.25rem",
  "6": "1rem"
};
const mobileSizes: Record<string, string> = {
  "1": "2rem",
  "2": "1.7rem",
  "3": "1.6rem",
  "4": "1.4rem",
  "5": "1.25rem",
  "6": "1rem"
};
const StyledHeader = styled.h2<HeaderProps>`
  // font-size: ${(props) => sizes[String(props.size)]};
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
  padding: 1rem;
  // padding-bottom: 1rem;
  width: 100%;
  font-weight: 400;
  color: var(--${(props) => props.color});

  ${(props) => {
    if (props.mobileSize) {
      return css`
        font-size: ${mobileSizes[String(props.size)]};
      `;
    }

    return css`
      font-size: ${sizes[String(props.size)]};
    `;
  }};

  ${(props) => {
    if (props.font === Font.Pacifico) {
      return css`
        font-family: "Pacifico", "Times New Roman", cursive;
      `;
    }

    return css`
      font-family: "Source Sans Pro", Impact, "Franklin Gothic Bold", sans-serif;
    `;
  }};

  ${(props) =>
    props.subtitle &&
    css`
      font-size: 1.5rem;
      font-weight: 300;

      & + & {
        margin-top: -1rem;
      }
    `};
  @media (max-width: 800px) {
  }
`;

const Header = ({ size, ...props }: HeaderProps) => {
  return <StyledHeader as={`h${size}`} size={size} {...props} />;
};

Header.defaultProps = {
  size: 1,
  color: Color.tertiary
};

export default Header;
