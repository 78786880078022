import styled, { css } from "styled-components";
import { Color } from "../types";

export type Alignment = "left" | "center" | "right";

export const Table = styled.table`
  display: flex;
  flex-direction: column;
  height: max-content;
  width: 100%;
  overflow: auto;
`;

export const TableHeader = styled.thead`
  width: max-content;
`;

export const TableBody = styled.tbody`
  display: flex;
  flex-direction: column;
  width: max-content;
`;

export const TableRow = styled.tr`
  display: flex;
  width: max-content;
`;

export const TableCell = styled.td<{
  align?: Alignment;
  color?: Color;
  $longCell?: boolean;
}>`
  justify-content: ${(props) => props.align || "center"};
  display: inline-flex;
  flex: 0 0 100px;
  padding: 0.5rem;

  font-weight: 300;
  color: ${(props) => `var(--${props.color || "tertiary"})`};
  text-align: ${(props) => props.align || "center"};

  ${(props) =>
    props.$longCell &&
    css`
      flex: 0 0 175px;
    `};
`;

export const TableHeaderCell = styled.th<{
  align?: Alignment;
  color?: Color;
  $longCell?: boolean;
}>`
  justify-content: ${(props) => props.align || "center"};
  padding: 0.5rem;
  display: inline-flex;
  flex: 0 0 100px;

  font-weight: 400;
  color: ${(props) => `var(--${props.color || "tertiary"})`};
  text-align: ${(props) => props.align || "center"};

  ${(props) =>
    props.$longCell &&
    css`
      flex: 0 0 175px;
    `};
`;

export const TableLink = styled.a`
  color: var(--sixth);
  font-weight: 400;
  font-size: 1rem;
  text-decoration: underline;
`;
