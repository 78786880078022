import styled, { css } from "styled-components";
import Flex from "../../../components/Flex";

export const AnalyticsBox = styled(Flex)<{
  $noBorder?: boolean;
  $decorative?: boolean;
  $shadow?: boolean;
  $knockout?: boolean;
  $knockoutBlue?: boolean;
  $knockoutDarkBlue?: boolean;
  $knockout5?: boolean;
  $split?: boolean;
}>`
  border: 1px solid var(--tertiary);
  border-radius: 0.25rem;
  flex: 1 1 100%;
  flex-direction: column;
  padding: 0.5rem;
  position: relative;
  width: 100%;

  ${(props) =>
    props.$noBorder &&
    css`
      border: none;
    `};

  ${(props) =>
    props.$decorative &&
    css`
      background: url("/images/analytics_decorative.webp");
      background-size: cover;
      padding-top: 2rem;
    `}

  ${(props) =>
    props.$decorative &&
    props.$shadow &&
    css`
      box-shadow: 4px 4px 32px 0px rgba(47, 53, 75, 0.1);
    `}

  ${(props) =>
    props.$knockout &&
    css`
      background: var(--linearGradient);
      color: var(--white);
    `}

  ${(props) =>
    props.$knockout5 &&
    css`
      background: var(--linearGradient5);
      color: var(--white);
    `}

  ${(props) =>
    props.$knockoutBlue &&
    css`
      background: var(--lightPurple);
      color: var(--tertiary);
    `}
    ${(props) =>
    props.$knockoutDarkBlue &&
    css`
      background: var(--tertiary);
      color: var(--lightPurple);
    `}
`;

export const AnalyticsBoxTitle = styled(Flex)<{
  $knockout?: boolean;
  $knockoutBlue?: boolean;
}>`
  border-bottom: 1px solid var(--tertiary);
  color: var(--tertiary);
  display: flex;
  font-size: 1.1rem;
  justify-content: space-between;
  padding: 0.25rem;
  width: 100%;

  ${(props) =>
    props.$knockout &&
    css`
      border-bottom: 1px solid var(--white);
      color: var(--white);
    `}

  ${(props) =>
    props.$knockoutBlue &&
    css`
      border-bottom: 1px solid var(--quaternary);
      color: var(--quaternary);
    `}

  .analytics-box-title-subtitle {
    font-weight: bold;
  }
`;

export const ChartWrapper = styled.div<{
  $text?: boolean;
  $textBlue?: boolean;
  $textGreen?: boolean;
}>`
  flex: 1 1 auto;
  position: relative;
  width: 100%;

  ${(props) =>
    (props.$text || props.$textBlue || props.$textGreen) &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      padding: 1rem;
      line-height: 1;
      text-decoration: underline;
    `};

  ${(props) =>
    props.$text &&
    css`
      font-size: 2rem;
      color: var(--quaternary);
    `};

  ${(props) =>
    props.$textGreen &&
    css`
      font-size: 1.3rem;
      color: var(--green);
    `};
`;
