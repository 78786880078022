import React from "react";
import { useParams } from "react-router-dom";
import AnalyticsPage from "./AnalyticsPage";
import AnalyticsMarket from "./AnalyticsMarket";
import AnalyticsMidTermRentalsMarket from "./AnalyticsMidTermRentalsMarket";
import AnalyticsRentalRegulations from "./AnalyticsRentalRegulations";
import AnalyticsAirbnbCalculator from "./AnalyticsAirbnbCalculator";
import AnalyticsInvestorGuides from "./AnalyticsInvestorGuides";
import AnalyticsMTRPage from "./AnalyticsMTRPage";
import AnalyticsSavedAnalysis from "./AnalyticsSavedAnalysis";
import AnalyticsZipcode from "./AnalyticsZipcode";

export default function AnalyticsRoute() {
  const { type, market, zipcode } = useParams<any>();

  if (type === "overview") {
    if (!market) {
      return <AnalyticsPage />;
    }
    if (zipcode) {
      return <AnalyticsZipcode />;
    }

    return <AnalyticsMarket />;
  }

  if (type === "str-calculator") {
    if (market === "saved-analysis") {
      return <AnalyticsSavedAnalysis />;
    }
    return <AnalyticsAirbnbCalculator />;
  }

  if (type === "rental-regulations") {
    return <AnalyticsRentalRegulations />;
  }

  if (type === "mid-term-rentals") {
    if (!market) {
      return <AnalyticsMTRPage />;
    }
    return <AnalyticsMidTermRentalsMarket />;
  }

  if (type === "investor-guides") {
    return <AnalyticsInvestorGuides />;
  }

  return <AnalyticsPage />;
}
