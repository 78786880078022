import { Pie } from "react-chartjs-2";
import React, { useContext } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, defaults } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Colors } from "../../../components/GlobalStyle";
import { Font } from "../../../components/Text";
import { AppContext } from "../../../components/AppContext";

defaults.font.family = Font.SourceSansPro;

ChartJS.register(ArcElement, Tooltip, ChartDataLabels);

const getAspectRatio = (
  largeScreen: boolean,
  mobileSize: boolean,
  isZipcode?: boolean,
  fullWidth?: boolean
) => {
  if (largeScreen) return 1.85;
  if (mobileSize) return 1.85;
  if (isZipcode && !largeScreen && !mobileSize) return 1.1;
  if (fullWidth) return 2.15;
  return 1.3;
};

export default function PieChart({ data }: { data: Record<string, any> }) {
  const labels: string[] = [];
  const values: number[] = [];
  const { mobileSize, largeScreen } = useContext(AppContext);

  Object.entries(data).forEach(([key, value]) => {
    if (key !== "market") {
      labels.push(key);
      values.push(value);
    }
  });

  return (
    <Pie
      data={{
        labels,
        datasets: [
          {
            labels,
            data: values.map((value) => value.toFixed(0)),
            backgroundColor: [
              Colors.secondary,
              Colors.eighth,
              Colors.quinary,
              Colors.seventh,
              Colors.tertiary,
              Colors.lightBlue,
              Colors.primary
            ]
          }
        ] as any
      }}
      options={{
        aspectRatio: getAspectRatio(largeScreen, mobileSize),
        layout: {
          padding: {
            bottom: 10,
            top: 10,
            left: 0,
            right: 0
          }
        },
        plugins: {
          datalabels: {
            color: Colors.tertiary,
            formatter: (value: number, context: any) => {
              // Find the smallest value in the dataset
              const minValue = Math.min(...context.dataset.data);
              // Only show label if it's not the smallest value
              if (value === minValue) {
                return null; // This will hide the label
              }
              return context.dataset.labels[context.dataIndex];
            },
            font: {
              size: 12,
              weight: "normal"
            },
            anchor: "end",
            padding: {
              bottom: 10
            },
            align: "end"
          }
        }
      }}
    />
  );
}
