import React, { useState } from "react";
import styled from "styled-components";

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const TooltipContent = styled.div<{ isVisible: boolean }>`
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  display: inline-block;
  background-color: var(--white);
  border: 1px solid var(--eighth);
  color: var(--tertiary);
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 43px rgba(0, 0, 0, 0.1);
  font-size: 0.875rem;
  width: 300px;
  z-index: 1000;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
  transition: opacity 0.3s, visibility 0.3s;
  margin-top: 0.5rem;
`;

interface TooltipProps {
  content: React.ReactNode;
  children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ content, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleTooltip = () => {
    setIsVisible(!isVisible);
  };

  return (
    <TooltipContainer onClick={toggleTooltip}>
      {children}
      <TooltipContent isVisible={isVisible}>{content}</TooltipContent>
    </TooltipContainer>
  );
};

export default Tooltip;
