import React, {
  ForwardedRef,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import styled, { css } from "styled-components";
import Portal from "./Portal";
import { Color } from "../types";
import { useMergeRefs } from "../hooks/useMergeRefs";

export enum Placement {
  bottom = "bottom",
  bottomStart = "bottom-start",
  bottomEnd = "bottom-end",
  top = "top"
}

export interface PopoverProps extends React.HTMLAttributes<HTMLDivElement> {
  arrow?: boolean;
  color?: Color;
  container?: RefObject<any>;
  isOpen: boolean;
  maxContent?: boolean;
  offsetX?: number;
  offsetY?: number;
  placement?: Placement;
  shadow?: boolean;
  target: string;
}

const StyledPopover = styled.div<{
  arrow?: boolean;
  isContained?: boolean;
  shadow?: boolean;
  $isLoaded?: boolean;
}>`
  // background-color: var(--${(props) => props.color});
  background: url("${process.env.PUBLIC_URL}/images/blue-fade.webp");
  background-size: cover;

  border-radius: 0rem 0.25rem 0.25rem 0.25rem;
  box-shadow: 0 0 43px rgba(0, 0, 0, 0.1);
  -webkit-appearance: none;
  opacity: 0;
  padding: 0.25rem;
  position: ${(props) => (props.isContained ? "absolute" : "fixed")};
  z-index: 1060;
  border-left: 1px solid var(--quaternary);
  border-right: 1px solid var(--quaternary);
  border-bottom: 1px solid var(--quaternary);

  ${(props) =>
    props.$isLoaded &&
    css`
      animation: fadeIn 166ms ease forwards;

      @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    `};
  li:hover {
    background: var(--quaternary);
    border-radius: 0.25rem;
  }

  ${(props) =>
    props.shadow &&
    css`
      box-shadow: 0 0px 16px rgba(0, 0, 0, 0.15);
      -webkit-appearance: none;
    `};

  ${(props) =>
    props.arrow &&
    css`
      &:after {
        bottom: -8px;
        content: "";
        left: 50%;
        position: absolute;

        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;

        border-top: 8px solid var(--${props.color});
        transform: translateX(-50%);
      }
    `};
`;

const Popover = React.forwardRef(
  (
    {
      arrow,
      children,
      container,
      isOpen,
      offsetX = 0,
      offsetY = 0,
      placement,
      target,
      style,
      ...props
    }: PopoverProps,
    ref: ForwardedRef<any>
  ) => {
    const popoverRef = useRef<HTMLDivElement>();
    const [pos, setPos] = useState({ left: 0, top: 0, width: 0 });
    const [localOpen, setLocalOpen] = React.useState(false);

    const mergedRef = useMergeRefs(ref, popoverRef);

    const setPlacementVars = useCallback(
      (element: any) => {
        const { left, height, top, width } = element.getBoundingClientRect();
        let currentOffsetX = offsetX;
        let currentOffsetY = offsetY;
        let elHeight = 0;
        let elWidth = 0;
        let position = {
          left: 0,
          top: 0,
          width: 0
        };

        if (container?.current) {
          const { left: parentLeft, top: parentTop } =
            container.current.getBoundingClientRect();
          currentOffsetX -= parentLeft;
          currentOffsetY -= parentTop;
        }

        if (popoverRef?.current) {
          const { height: pHeight, width: pWidth } =
            popoverRef.current?.getBoundingClientRect();
          elHeight = pHeight;
          elWidth = pWidth;
        }

        switch (placement) {
          case "top":
            position = {
              left: left - elWidth / 2 + width / 2 + currentOffsetX,
              top: top - elHeight + currentOffsetY - (arrow ? 8 : 0),
              width
            };
            break;
          case "bottom-start":
            position = {
              left: left + currentOffsetX,
              top: top + height + currentOffsetY,
              width: Math.max(width, elWidth)
            };
            break;
          case "bottom-end":
            position = {
              left: left + currentOffsetX,
              top: top + height + currentOffsetY,
              width: Math.max(width, elWidth)
            };
            break;
          case "bottom":
          default:
            position = {
              left: left - elWidth / 2 + width / 2 + currentOffsetX,
              top: top + height + currentOffsetY,
              width: Math.max(width, elWidth)
            };
            break;
        }
        return position;
      },
      [container, placement, offsetX, offsetY]
    );

    useEffect(() => {
      if (target) {
        const element = document.getElementById(target);

        if (element && isOpen) {
          setPos(setPlacementVars(element));
          setTimeout(() => {
            setLocalOpen(isOpen);
          }, 10);
        }
      }
    }, [isOpen, target, setPlacementVars]);

    return (
      <Portal node={container?.current}>
        {isOpen && (
          <StyledPopover
            {...props}
            $isLoaded={localOpen}
            arrow={arrow}
            isContained={container?.current}
            ref={mergedRef}
            style={{
              left: pos.left,
              top: pos.top,
              minWidth: pos.width,
              maxHeight: "260px",
              overflowY: "auto",
              ...style
            }}
          >
            {children}
          </StyledPopover>
        )}
      </Portal>
    );
  }
);

Popover.defaultProps = {
  arrow: false,
  // color: Color.white,
  color: Color.tertiary,
  offsetX: 0,
  offsetY: 0,
  placement: Placement.bottomStart
};

export default Popover;
