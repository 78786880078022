import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import AnalyticsWrapper from "./components/AnalyticsWrapper";
import AnalyticsHeader from "./components/AnalyticsHeader";
import LazyImage from "../../components/LazyImage";
import Header from "../../components/Header";

const AnalyticsCard = ({ listing }: { listing: any }) => (
  <Link
    to={listing.url}
    target="_blank"
    style={{
      display: "block",
      textAlign: "center",
      background: "var(--white)",
      color: "white",
      padding: "8px",
      borderRadius: "4px",
      textDecoration: "none"
    }}
    className="listing-card-link"
  >
    <div
      style={{
        border: "1px solid #ddd",
        borderRadius: "8px",
        padding: "16px",
        height: "500px",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
      }}
      className="listing-card"
    >
      <div>
        <h2 style={{ fontSize: "1.2em", marginBottom: "8px" }}>
          {listing.address}
        </h2>
        <LazyImage
          src={`${process.env.PUBLIC_URL}/images/short-term-rental.svg`}
          alt={listing.title}
          style={{
            width: "100%",
            height: "200px",
            objectFit: "cover",
            marginBottom: "12px",
            borderRadius: "4px"
          }}
        />
      </div>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "8px"
          }}
        >
          {/* <span>Price:</span> */}
          <span style={{ fontWeight: "bold", color: "var(--tertiary)" }}>
            ${listing.price.toLocaleString()}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: "12px",
            fontSize: "1em",
            color: "var(--quaternary)"
          }}
        >
          <span style={{ color: "var(--secondary)", fontWeight: "500" }}>
            {listing.bedrooms} beds
          </span>
          <span style={{ margin: "0 8px" }}>|</span>
          <span style={{ color: "var(--secondary)", fontWeight: "500" }}>
            {listing.bathrooms} baths
          </span>
          <span style={{ margin: "0 8px" }}>|</span>
          <span style={{ color: "var(--secondary)", fontWeight: "500" }}>
            {listing.sqft} sq ft
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "8px"
          }}
        >
          <span>Projected Revenue:</span>
          <span style={{ fontWeight: "500", color: "var(--tertiary)" }}>
            ${listing.annualRevenue.toLocaleString()}/year
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "8px"
          }}
        >
          <span>Cap Rate:</span>
          <span style={{ fontWeight: "500", color: "var(--tertiary)" }}>
            {(listing.capRate * 100).toFixed(2)}%
          </span>
        </div>
      </div>
    </div>
  </Link>
);

export default function AnalyticsSavedAnalysis() {
  const location: any = useLocation();
  const [savedAnalysis, setSavedAnalysis] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchSavedAnalysis = async () => {
      const userId = location.state?.userId;
      if (!userId) {
        console.error("userId not found in location state");
        setLoading(false);
        return;
      }
      try {
        const response = await fetch(
          `https://zmura59afa.execute-api.us-west-2.amazonaws.com/v1/airbnb-estimate/saved-analysis/${userId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json"
            }
          }
        );
        if (!response.ok) {
          throw new Error(`Error fetching data: ${response.statusText}`);
        }
        const data = await response.json();
        setSavedAnalysis(data.items || []);
      } catch (error) {
        console.error("Failed to fetch saved analysis:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchSavedAnalysis();
  }, []);

  return (
    <AnalyticsWrapper style={{ minHeight: "100vh" }}>
      <Helmet>
        <title>
          Saved Airbnb Analysis | Profitable Short-Term Rental Investments
        </title>
        <meta
          name="description"
          content="View your saved short term rental analysis."
        />
        <meta
          name="keywords"
          content="Airbnb for sale, short-term rental investments, vacation rental properties, Airbnb investment opportunities"
        />
        <link
          rel="canonical"
          href="https://www.getchalet.com.com/analytics/str-calculator/saved-analysis"
        />
        <meta
          property="og:title"
          content="Saved Airbnb Analysis | Profitable Short-Term Rental Investments"
        />
        <meta
          property="og:description"
          content="View your saved short term rental analysis."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.getchalet.com/analytics/str-calculator/saved-analysis"
        />
        <meta
          property="og:image"
          content="https://www.getchalet.com/images/airbnbs-for-sale-featured.webp"
        />
      </Helmet>
      <AnalyticsHeader />
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-start", // Align items to the left
          gap: "1rem", // Add gap between cards
          maxWidth: "90vw", // Adjust based on your design
          margin: "1rem auto"
        }}
      >
        <Header>Your Saved Airbnb Analysis</Header>
        {!savedAnalysis && "Loading"}
        {savedAnalysis &&
          savedAnalysis?.map((analysis, idx) => {
            const key = `analysis-${idx}`;
            return <AnalyticsCard key={key} listing={analysis} />;
          })}
      </div>
    </AnalyticsWrapper>
  );
}
