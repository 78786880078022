/* eslint-disable camelcase */
import React, { useContext, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import useSWR from "swr";
import { Link, useHistory, useParams } from "react-router-dom";
import { AppContext } from "../../../components/AppContext";
import Select from "../../../components/Select";
import fetcher from "../../../utils/fetch";
import Flex from "../../../components/Flex";
import ShareButton from "./ShareButton";

export interface AnalyticsHeaderProps {
  children?: React.ReactNode;
  onSelect?(e: any): void;
}

const MobileWrapper = styled(Flex)`
  flex-direction: column;
  margin-top: 1rem;
  width: 100%;
  position: relative;
`;

const ShareButtonWrapper = styled.div`
  align-self: flex-end;
  padding: 0.5rem 1rem;
`;

const ScrollWrapper = styled.div`
  overflow: auto;
  width: 100%;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

const HeaderList = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding: 1rem;
  background-color: var(--white);
  width: 100%;

  @media (max-width: 720px) {
    width: max-content;
    padding: 1rem 1rem calc(1rem + 3px) 1rem;
  }
`;

const AnalyticsSearch = styled(Select)`
  background: none;
  border: 1px solid var(--tertiary);
  display: flex;
  flex-direction: column;
  font-weight: 300;
  max-height: 260px;
  min-width: 350px;
  padding: 0.5rem;
  margin-top: inherit;
  margin-bottom: -1rem;
  color: var(--black);
  border-radius: 0.5rem;
  overflow-y: auto;
  text-decoration: underline;
  ::placeholder {
    //color: var(--quinary);
    background-image: linear-gradient(178deg, #f4246c 1.34%, #2f354b 98.66%);
    background-clip: text;
    font-weight: 300;
    color: transparent;
    text-decoration: underline;
    opacity: 1;
  }
`;

const AnalyticsHeaderButton = styled(Link)<{ $active?: boolean }>`
  border-radius: 0.25rem;
  display: block;
  font-size: 1.3rem;
  padding: 0.25rem 1rem;
  color: var(--tertiary);
  text-decoration: none;

  ${(props) =>
    props.$active &&
    css`
      text-decoration: underline solid var(--tertiary) 2px;
    `};
`;

export default function AnalyticsHeader({
  children,
  onSelect
}: AnalyticsHeaderProps) {
  const history = useHistory();
  const { market, type } = useParams<Record<string, string>>();
  const { mobileSize } = useContext(AppContext);
  const { address, setAddress } = useContext(AppContext);

  const { data: markets } = useSWR<any[]>(
    !children
      ? "https://zmura59afa.execute-api.us-west-2.amazonaws.com/v1/markets"
      : undefined,
    fetcher,
    {
      fallbackData: []
    }
  );

  const handleSelect = (e: any) => {
    history.push(`/analytics/${type}/${e.value.value}`);
  };

  useEffect(() => {
    if (markets && markets.length > 0) {
      onSelect?.({ value: markets });
    }
  }, [markets, onSelect]);

  const options = (markets || []).map(({ market: mk, market_slug }: any) => ({
    label: mk,
    value: market_slug
  }));

  const scrollRef = React.useCallback(
    (node) => {
      if (node) {
        if (mobileSize) {
          const activeTab = node.querySelector("a[data-active]");
          if (activeTab) {
            activeTab.scrollIntoView({
              behavior: "instant",
              inline: "center",
              block: "nearest"
            });
          }
        }
      }
    },
    [mobileSize, type, market]
  );

  if (mobileSize) {
    return (
      <MobileWrapper>
        <ScrollWrapper
          style={{
            position: "fixed",
            top: mobileSize ? "78px" : "82px",
            left: "0",
            zIndex: "999"
          }}
        >
          <HeaderList ref={scrollRef}>
            <li>
              <AnalyticsHeaderButton
                $active={type === "overview"}
                to={`/analytics/overview${market ? `/${market}` : ""}`}
                data-active={type === "overview" ? "true" : undefined}
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundImage: "var(--linearGradient)",
                  backgroundClip: "text",
                  color: "transparent"
                  // textDecoration: "underline double 4px var(--quinary)"
                }}
              >
                <img
                  // src="path/to/your/icon.png" // replace with your icon path or use an <Icon /> component
                  src={`${process.env.PUBLIC_URL}/images/sparking-stars.png`}
                  alt="Icon"
                  style={{ marginRight: "2px", width: "25px", height: "25px" }}
                />
                Market Dashboard
              </AnalyticsHeaderButton>
            </li>
            <li>
              <AnalyticsHeaderButton
                $active={type === "str-calculator"}
                to="/analytics/str-calculator"
                data-active={type === "str-calculator" ? "true" : undefined}
              >
                Airbnb Calculator
              </AnalyticsHeaderButton>
            </li>
            <li>
              <AnalyticsHeaderButton
                $active={type === "rental-regulations"}
                to={`/analytics/rental-regulations${
                  market ? `/${market}` : ""
                }`}
                data-active={type === "rental-regulations" ? "true" : undefined}
              >
                Rental Regulation
              </AnalyticsHeaderButton>
            </li>
            <li>
              <AnalyticsHeaderButton
                $active={type === "mid-term-rentals"}
                to={`/analytics/mid-term-rentals${market ? `/${market}` : ""}`}
                data-active={type === "mid-term-rentals" ? "true" : undefined}
              >
                Mid-Term Rentals
              </AnalyticsHeaderButton>
            </li>
            <li>
              <AnalyticsHeaderButton
                $active={type === "investor-guides"}
                to={`/analytics/investor-guides${market ? `/${market}` : ""}`}
                data-active={type === "investor-guides" ? "true" : undefined}
              >
                Investor Guides
              </AnalyticsHeaderButton>
            </li>
          </HeaderList>
        </ScrollWrapper>
        {type === "str-calculator" && children}
        {type !== "str-calculator" && (
          <AnalyticsSearch
            isSearchable
            options={options}
            placeholder={
              mobileSize ? "Search any market" : "Search any market in the US"
            }
            onChange={handleSelect}
            value={options.find(({ value: optVal }) => optVal === market)}
          />
        )}
        <ShareButtonWrapper>
          <ShareButton />
        </ShareButtonWrapper>
      </MobileWrapper>
    );
  }

  return (
    <HeaderList
      style={{
        position: "fixed",
        top: "82px",
        left: "0",
        zIndex: "999",
        borderBottom: "1px solid var(--tertiary)"
      }}
    >
      <li>
        <AnalyticsHeaderButton
          // $active={history.location.pathname.includes("analytics")}
          $active={type === "overview"}
          to={`/analytics/overview${market ? `/${market}` : ""}`}
          data-active={type === "overview" ? "true" : undefined}
          style={{
            display: "flex",
            alignItems: "center",
            backgroundImage: "var(--linearGradient)",
            backgroundClip: "text",
            // borderBottom: "4px double var(--quinary)",
            color: "transparent"
            // textDecoration: "underline double 4px var(--quinary)"
          }}
        >
          <img
            // src="path/to/your/icon.png" // replace with your icon path or use an <Icon /> component
            src={`${process.env.PUBLIC_URL}/images/sparking-stars.png`}
            alt="Icon"
            style={{ marginRight: "2px", width: "25px", height: "25px" }}
          />
          Market Dashboard
        </AnalyticsHeaderButton>
      </li>
      <li>
        <AnalyticsHeaderButton
          $active={type === "str-calculator"}
          to="/analytics/str-calculator"
        >
          Airbnb Calculator
        </AnalyticsHeaderButton>
      </li>
      <li>
        <AnalyticsHeaderButton
          $active={type === "rental-regulations"}
          to={`/analytics/rental-regulations${market ? `/${market}` : ""}`}
          data-active={type === "rental-regulations" ? "true" : undefined}
        >
          Rental Regulation
        </AnalyticsHeaderButton>
      </li>
      <li>
        <AnalyticsHeaderButton
          $active={type === "mid-term-rentals"}
          to={`/analytics/mid-term-rentals${market ? `/${market}` : ""}`}
          data-active={type === "mid-term-rentals" ? "true" : undefined}
        >
          Mid-Term Rentals
        </AnalyticsHeaderButton>
      </li>
      <li>
        <AnalyticsHeaderButton
          $active={type === "investor-guides"}
          to={`/analytics/investor-guides${market ? `/${market}` : ""}`}
        >
          Investor Guides
        </AnalyticsHeaderButton>
      </li>
      <li>
        {type === "str-calculator" && children}
        {type !== "str-calculator" && (
          <AnalyticsSearch
            style={{
              padding: "0.5rem",
              fontWeight: 300,
              minWidth: "350px",
              background: "none",
              textDecoration: "underline",
              border: "1px solid var(--tertiary)"
            }}
            isSearchable
            options={options}
            placeholder={
              mobileSize ? "Search any market" : "Search any market in the US"
            }
            onChange={handleSelect}
            value={options.find(({ value: optVal }) => optVal === market)}
          />
        )}
      </li>
      <ShareButton style={{ marginLeft: "auto" }} center={false} />
    </HeaderList>
  );
}
