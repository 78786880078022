import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { Helmet } from "react-helmet";
import "../../styles/Analytics.scss";
import styled from "styled-components";
import Section from "../../components/Section";
import { Featured, FeaturedCenter } from "../../components/Featured";
import fetcher from "../../utils/fetch";
import Flex from "../../components/Flex";
import AnalyticsHeader from "./components/AnalyticsHeader";
import AnalyticsWrapper from "./components/AnalyticsWrapper";
import SkeletonLoader from "../../components/SkeletonLoader";
import MultiSelect from "../../components/MultiSelect";
import AnalyticsMarket from "./AnalyticsMarket";
import Header from "../../components/Header";
import Text from "../../components/Text";
import Toggle from "../../components/Toggle";

const sortOptions = [
  {
    label: "Gross Yield",
    value: "gross_yield"
  },
  {
    label: "Annual Revenue",
    value: "annual_revenue"
  }
];
const FAQSection = styled.section`
  background: var(--white);
  padding: 4rem 2rem;
  max-width: 95vw;
  margin: 0 auto;

  h2 {
    color: var(--tertiary);
    text-align: center;
    margin-bottom: 2rem;
  }

  .faq-item {
    margin-bottom: 2rem;
    border-radius: 0.5rem;
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    h3 {
      color: var(--tertiary);
      margin-bottom: 1rem;
    }

    p {
      color: var(--secondary);
      line-height: 1.6;
    }
  }
`;

// Add this function to format numbers with commas
const formatNumberWithCommas = (num: string | number) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const AnalyticsPage = () => {
  const { market: marketPathParam } = useParams<any>();
  const [sortType, setSortType] = React.useState<string | undefined>();
  const [viewMode, setViewMode] = React.useState<"grid" | "list">("grid");
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [selectedStates, setSelectedStates] = useState<string[]>([]);

  const { data: markets } = useSWR<any[]>(
    "https://zmura59afa.execute-api.us-west-2.amazonaws.com/v1/markets",
    fetcher,
    {
      fallbackData: []
    }
  );

  const renderMarketItem = (market: any, key: string) => {
    if (viewMode === "grid") {
      return (
        <a
          key={key}
          className="featured-post"
          href={`/analytics/overview/${market.market_slug}`}
        >
          <div className="image">
            <img
              src={`https://d2mv0zzolg6397.cloudfront.net/market-images-thumbs/${market.market_slug}.jpg`}
              alt={market.market}
              loading="lazy"
            />
          </div>
          <div className="content">
            <h2 className="title">{market.market}</h2>
            <div className="stats">
              <div className="revenue">
                <span>Annual Revenue</span>
                <span id="value">
                  {formatNumberWithCommas(market.annual_revenue)}
                </span>
              </div>
              <div className="gross">
                <span>Gross Yield</span>
                <span id="value">{market.gross_yield}</span>
              </div>
              <div className="medianHomeValue">
                <span>Median Home Value</span>
                <span id="value">
                  ${formatNumberWithCommas(market.median_home_value)}
                </span>
              </div>
            </div>
          </div>
        </a>
      );
    }
    return (
      <div key={key} className="market-list-item">
        <img
          src={`https://d2mv0zzolg6397.cloudfront.net/market-images-thumbs/${market.market_slug}.jpg`}
          alt={market.market}
        />
        <div className="market-info">
          <h3>{market.market}</h3>
          <div className="market-stats">
            <span>
              Gross Yield: <strong>{market.gross_yield}</strong>
            </span>
            <span>
              Annual Revenue:{" "}
              <strong>${formatNumberWithCommas(market.annual_revenue)}</strong>
            </span>
            <span>
              Median Home Value:{" "}
              <strong>
                ${formatNumberWithCommas(market.median_home_value)}
              </strong>
            </span>
          </div>
        </div>
        <a href={`/analytics/overview/${market.market_slug}`}>View Details</a>
      </div>
    );
  };

  const featuredComponents = (markets || [])
    .filter((market: any) => {
      const includesFilter =
        selectedFilters.length === 0 ||
        selectedFilters.includes(market.market_type);
      const includesState =
        selectedStates.length === 0 ||
        selectedStates.includes(market.state_name);
      return includesFilter && includesState;
    })
    .sort((a: any, b: any) => {
      if (!sortType) return 0;

      const option = sortOptions.find((opt) => opt.label === sortType);
      if (!option) return 0;

      const field = option.value;
      // Convert strings to numbers for comparison if needed
      const aValue = field === "gross_yield" ? parseFloat(a[field]) : a[field];
      const bValue = field === "gross_yield" ? parseFloat(b[field]) : b[field];

      return bValue - aValue; // Sort in descending order
    })
    .map((market: any, index: number) =>
      renderMarketItem(market, `market-${index}`)
    );

  // Add this debug log to see what data we're working with
  console.log("Debug market data:", {
    selectedStates,
    availableStates: markets?.map((m) => m.state_name),
    firstMarket: markets?.[0]
  });

  const handleApply = (selectedOptions: string[]) => {
    setSelectedFilters(selectedOptions);
  };

  const handleStateApply = (selectedOptions: string[]) => {
    setSelectedStates(selectedOptions);
  };

  const handleSortApply = (selectedOptions: string[]) => {
    setSortType(selectedOptions[0]);
  };

  const [marketOptions, stateOptions] = React.useMemo(() => {
    if (!markets) return [[], []];
    return [
      [...new Set(markets.map((m) => m.market_type))],
      [...new Set(markets.map((m) => m.state_name))]
    ];
  }, [markets]);

  if (marketPathParam) {
    return <AnalyticsMarket />;
  }

  return (
    <AnalyticsWrapper>
      <Helmet>
        <title>
          FREE AI-Powered Airbnb Rental Analytics: STR Rental Income, Airbnb
          Demand and more
        </title>
        <meta
          name="description"
          content="Discover our free Airbnb investment analytics tool offering comprehensive insights into occupancy rates, average daily rates, revenue estimates, and more. Perfect for investors at any level, with tailored guides, regulation info, and an ROI estimator."
        />
        <link
          rel="canonical"
          href="https://www.getchalet.com/analytics/overview/"
        />
        {/* Open Graph meta tags */}
        <meta
          property="og:title"
          content="FREE Airbnb Rental Analysis/Tools You Need"
        />
        <meta
          property="og:description"
          content="Free Short-term rental analysis to get 
          you best ROI. Free Market Reports, Airbnb Profit 
          Calculator, Airbnb Regulation Analysis. 
          Real Hard Data from local experts."
        />
        <meta
          property="og:url"
          content="https://www.getchalet.com/analytics/overview/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.getchalet.com/images/analytics_dashboard.webp"
        />{" "}
        {/* Replace with actual image URL */}
        <meta property="og:site_name" content="Chalet" />
        {/* Twitter Card meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Unlock Free Airbnb Investment Analytics | Chalet"
        />
        <meta
          name="twitter:description"
          content="Free Short-term rental analysis to get 
          you best ROI. Free Market Reports, Airbnb Profit 
          Calculator, Airbnb Regulation Analysis. 
          Real Hard Data from local experts."
        />
        <meta
          name="twitter:image"
          content="https://www.getchalet.com/og-image-analytics.jpg"
        />{" "}
        {/* Replace with actual image URL */}
      </Helmet>
      <AnalyticsHeader />
      <Section id="analytics-main">
        <div id="analytics_landing">
          {/* <Header
            style={{
              padding: 0,
              fontSize: "1rem",
              color: "var(--quarternary)"
            }}
            size={2}
          >
            All Short-Term Rental Tools You Need in One Place
          </Header> */}
          <div className="analytics_intro">
            Are you exploring short-term rental investments and seeking accurate
            data to guide your decisions? Our completely free Airbnb investment
            analytics dashboard provides powerful insights into key metrics like
            occupancy rates, average daily rates, and supply analysis.
          </div>
          <div className="analytics_intro">
            With tools such as a revenue and ROI estimator (Airbnb Calculator),
            investor guides, regulation updates, and even mid-term rental
            analysis, you&apos;ll have everything you need to succeed in the
            short-term rental market.
          </div>
          <div id="analytics_markets">
            <Featured
              title="AI-Powered Airbnb Analytics"
              actions={
                <Flex style={{ gap: "1rem" }}>
                  <MultiSelect
                    options={marketOptions}
                    onApply={handleApply}
                    placeholder="Market Types"
                  />
                  <MultiSelect
                    options={stateOptions}
                    onApply={handleStateApply}
                    placeholder="States"
                  />
                  <MultiSelect
                    options={sortOptions.map(({ label }) => label)}
                    onApply={handleSortApply}
                    placeholder="Unsorted"
                    prefix="Sort"
                    single
                  />
                  <div className="view-toggle-desktop">
                    <Toggle
                      options={[
                        {
                          label: <>Grid</>,
                          value: "grid",
                          className: viewMode === "grid" ? "active" : ""
                        },
                        {
                          label: "List",
                          value: "list",
                          className: viewMode === "list" ? "active" : ""
                        }
                      ]}
                      value={viewMode}
                      onChange={(value: any) =>
                        setViewMode(value as "grid" | "list")
                      }
                    />
                  </div>
                </Flex>
              }
              isAnalytics
            >
              <div className={`market-view ${viewMode}`}>
                {markets?.length === 0 ? (
                  <SkeletonLoader />
                ) : (
                  featuredComponents
                )}
              </div>
            </Featured>
          </div>
        </div>
      </Section>
      <FAQSection>
        <Header size={2}>Frequently Asked Questions</Header>
        <div className="faq-item">
          <Header size={3}>
            What data does the Airbnb analytics tool provide?
          </Header>
          <Text>
            Our tool offers detailed insights into key metrics such as occupancy
            rates, average daily rates (ADR), supply analysis, revenue
            projections, and even the impact of amenities. It also includes
            market-specific information like local regulations, mid-term rental
            opportunities, and an Airbnb calculator for ROI estimation.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={3}>
            How can I use the tool to find the best markets for Airbnb
            investments?
          </Header>
          <Text>
            You can filter markets based on various factors such as occupancy
            rates, ADR, and regulations. This allows you to compare different
            markets to find the most profitable ones for short-term rental
            investments.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={3}>
            Does the tool provide information on Airbnb regulations?
          </Header>
          <Text>
            Yes, our dashboard includes the latest information on Airbnb
            regulations for each market, so you can stay compliant and
            understand how local rules might impact your investment.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={3}>
            Can I calculate my potential revenue and ROI using this tool?
          </Header>
          <Text>
            Absolutely! Our Airbnb Calculator is designed to estimate your
            revenue and ROI based on specific property data. It allows you to
            adjust key variables like occupancy rate, nightly rates, and
            expenses to give you a clear picture of your potential returns.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={3}>
            Does the tool cover mid-term rentals as well as short-term rentals?
          </Header>
          <Text>
            Yes, our analytics tool provides insights into mid-term rental
            opportunities, helping you assess whether this option could be a
            profitable strategy in your chosen market.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={3}>How often is the data updated?</Header>
          <Text>
            Our data is updated monthly to ensure you&apos;re getting the most
            accurate and up-to-date insights on market trends, regulations, and
            performance metrics.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={3}>Is this tool really free?</Header>
          <Text>
            Yes, our analytics tool is completely free to use, with no hidden
            fees. You can access all the insights, data, and analysis at no
            cost.
          </Text>
        </div>
      </FAQSection>
    </AnalyticsWrapper>
  );
};

export default AnalyticsPage;
