import React, { useContext, useRef } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { PopupButton } from "@typeform/embed-react";
import Slider from "react-slick";
import { AppContext } from "../../components/AppContext";
import Text from "../../components/Text";
import Header from "../../components/Header";
import useCookies from "../../hooks/useCookies";
import Flex from "../../components/Flex";
import SectionFooter from "../../components/SectionFooter";
import SplitView from "../../components/SplitView";
import Group from "../../components/Group";
import Section from "../../components/Section";
import { Color } from "../../types";
import "../../styles/RealEstateAgents.scss";
import LiveMarkets from "../../liveMarkets";
import Image from "../../components/Image";
import { VerticalDivider, HorizontalDivider } from "../../components/Divider";

const Container = styled(Flex)`
  // flex-direction: column;
  font-size: 2.5rem;
  height: 100vh;
  // width: 50%;
  justify-content: center;
  line-height: 1;
  h1 {
    font-weight: 300;
    font-size: 3.5rem;
    // text-align: center;
    margin-bottom: 0;
    text-align: left;
    // padding-bottom: 1rem;
  }
  h3 {
    font-weight: 300;
    font-size: 1.6rem;
    // text-align: center;
    margin: 0;
  }
  video {
    margin: 2rem;
    max-width: 450px;
    border-radius: 0.5rem;
  }
  padding: 5rem 0;
  .first ${Text} {
    white-space: nowrap;
    font-size: 3.5rem;
  }
  .second h1 {
    font-size: 1.8rem;
    font-weight: 300;
    margin: 0;
    color: var(--tertiary);
  }
  .third ${Text} {
    font-size: 1.5rem;
  }
  .first {
    padding: 1rem;
    padding-left: 1rem;
    padding-bottom: 0;
    // width: 50%;
  }
  .second,
  .third {
    align-items: center;
    display: flex;
    font-size: 1rem;
    padding-top: 0.75rem;
    padding: 1rem;
    padding-left: 1rem;
    // width: 50%;
  }
  .second {
    padding-top: 0;
  }
  .third {
    // display: block;
    // text-align: left;
    padding: 0rem;
  }
  ${Group} {
    :first-of-type {
      margin-top: 6rem;
    }
  }

  @media (max-width: 810px) {
    // justify-content: start;
    align-items: flex-start;
    margin-top: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    h3 {
      font-size: 2rem;
    }
    .first {
      text-align: center;
      h1 {
        font-size: 2.2rem;
        text-align: left;
        ${Text} {
          font-size: 2.2rem;
          text-align: left;
          line-height: 2.2rem;
        }
      }
    }
    .second {
      // display: none;
      h3 {
        font-size: 1.2rem;
        text-align: left;
        ${Text} {
          font-size: 1.2rem !important;
        }
      }
    }
    video {
      display: none;
    }
    .second {
      padding-bottom: 1rem;
    }
    .third ${Text} {
      padding-bottom: 1rem;
    }
    .second,
    .third {
      text-align: center;
    }
    .third {
      flex-direction: column;
    }
  }
`;

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  // centerMode: true,
  // centerPadding: "60px",
  rows: 1,
  // variableWidth: true,

  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true
        // centerMode: true,
        // centerPadding: "60px"
      }
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        centerMode: false
        // centerPadding: "60px"
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        arrows: false,
        centerPadding: "60px"
      }
    }
  ]
};

const RealEstateAgents = () => {
  const { getAllCookies } = useCookies();
  const { marketPathParam } = useParams<any>();

  const { mobileSize } = useContext(AppContext);

  const bodyRef = useRef<HTMLDivElement>();

  const cookies: Record<string, string> = {};

  getAllCookies().forEach(([cookieName, val]) => {
    cookies[cookieName] = val;
  });

  const titleString = `Real Estate Agents With Airbnb Rental Expertise | ${
    LiveMarkets[marketPathParam] || ""
  }`;
  const descriptionString = `Airbnb Realtors. Find the perfect short-term rental investment in the ${
    LiveMarkets[marketPathParam] || ""
  } market with the help of a local experts. Our top Airbnb realtors
   know the market inside and out and can guide you to the best 
   investment with the highest ROI. Connect with Chalet today`;

  const urlSlug = marketPathParam
    ? `https://www.getchalet.com/real-estate-agents/${marketPathParam}`
    : "https://www.getchalet.com/real-estate-agents";

  return (
    <>
      <Helmet>
        <link rel="canonical" href={urlSlug} />
        <title>{titleString}</title>
        <meta property="og:type" content="website" />
        <meta name="description" content={descriptionString} />
        <meta property="og:title" content={titleString} />
        <meta property="og:description" content={descriptionString} />
        <meta property="og:image" content="" />
        <meta property="og:url" content={urlSlug} />
        <meta property="og:site_name" content="Chalet" />
      </Helmet>
      <Container ref={bodyRef as any}>
        <div
          style={{
            // maxWidth: mobileSize ? "100%" : "50%",
            paddingRight: mobileSize ? "0" : "4rem"
          }}
        >
          <div className="first">
            <h1 style={{ lineHeight: mobileSize ? "2.6rem" : "4.8rem" }}>
              Real Estate Agents With{" "}
              <Text
                style={{
                  display: "block",
                  // fontSize: mobileSize ? "2rem" : "initial",
                  fontWeight: 400,
                  paddingBottom: mobileSize ? "1rem" : "2rem",
                  whiteSpace: "initial"
                }}
              >
                {" "}
                Airbnb Rental Investing Expertise
              </Text>
            </h1>
          </div>
          <div className="second">
            <h3>
              Let our agents guide you to
              <Text
                style={{
                  fontSize: mobileSize ? "2rem" : "1.6rem",
                  fontWeight: 400,
                  color: "var(--quinary)"
                }}
              >
                &nbsp;the best short-term rental
                <Text style={{ fontSize: mobileSize ? "2rem" : "1.6rem" }}>
                  &nbsp;investments{marketPathParam && " in"}
                </Text>{" "}
                <Text
                  style={{
                    color: "var(--tertiary)",
                    fontWeight: "500",
                    // textDecoration: "underline",
                    fontSize: mobileSize ? "2rem" : "1.6rem"
                  }}
                >
                  {LiveMarkets[marketPathParam]}
                </Text>
              </Text>
              &nbsp;
              <Text
                // block
                style={{
                  fontSize: mobileSize ? "2rem" : "1.6rem",
                  fontWeight: 500
                  // color: "var(--quaternary)",
                  // background: "var(--tertiary)",
                  // padding: "1rem",
                  // marginTop: "-1.75rem"
                }}
              >
                {/* {!marketPathParam && "in "}{" "} */}
                {!marketPathParam && <a href="#locations">in top US markets</a>}
              </Text>
            </h3>
          </div>
          <div className="third">
            <PopupButton
              id="DU51pTsh"
              // open="load"
              // data-tf-lazy="false"
              shareGaInstance="true"
              hidden={{
                market_of_interest:
                  LiveMarkets[marketPathParam] || "Not Specified",
                source_page: urlSlug
              }}
              // data-id="connect-with-realtor"
              style={{
                // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                backgroundColor: mobileSize
                  ? "var(--quinary)"
                  : "var(--quinary)",
                textTransform: "uppercase",
                color: "var(--white)",
                padding: "1rem",
                border: 0,
                fontWeight: 400,
                width: mobileSize ? "100%" : "initial",
                textDecoration: "none",
                display: mobileSize ? "block" : "initial",
                fontSize: "1rem",
                cursor: "pointer",
                textAlign: "center",
                boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                borderRadius: "0.25rem",
                // display: mobileSize ? "block" : "initial",
                margin: mobileSize ? "1rem auto" : "1rem"
              }}
              className="connect-with-realtor_popup_top"
            >
              Connect With Airbnb Realtor
            </PopupButton>
            {/* <PopupButton
              id="lMhk4Fej"
              shareGaInstance="true"
              hidden={{
                // market_of_interest: selectedMarket,
                // services: selectedService,
                source_page: "/(relator-page-chat-with-str-expert)"
              }}
              style={{
                marginLeft: mobileSize ? 0 : "1rem",
                background: mobileSize ? "none" : "#dadaeb",
                color: "var(--tertiary)",
                border: mobileSize ? "none" : "1px solid var(--tertiary)",
                padding: "1rem",
                textDecoration: mobileSize ? "underline" : "underline",
                fontSize: mobileSize ? "1.2rem" : "1rem",
                width: mobileSize ? "100%" : "initial",
                borderRadius: "0.25rem",
                cursor: "pointer",
                textTransform: "uppercase",
                marginTop: mobileSize ? "-1rem" : "initial"
              }}
              data-id="talk-to-str-expert"
              className="talk-to-str-expert_button_top btn-consult"
            >
              Talk to STR Expert First
            </PopupButton> */}
            {/* <PopupButton
              id="DcwsVbrt"
              // open="load"
              // data-tf-lazy="false"
              shareGaInstance="true"
              hidden={{
                market_of_interest:
                  LiveMarkets[marketPathParam] || "Not Specified",
                source_page: urlSlug
              }}
              // data-id="connect-with-realtor"
              style={{
                // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                backgroundColor: mobileSize
                  ? "var(--tertiary)"
                  : "var(--tertiary)",
                textTransform: "uppercase",
                color: "var(--white)",
                padding: "1rem",
                border: 0,
                width: mobileSize ? "100%" : "initial",
                textDecoration: "none",
                display: mobileSize ? "block" : "initial",
                fontSize: "1rem",
                cursor: "pointer",
                textAlign: "center",
                boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                borderRadius: "0.25rem",
                // display: mobileSize ? "block" : "initial",
                margin: mobileSize ? "1rem auto" : "1rem"
              }}
              className="connect-with-realtor_popup_top"
            >
              Talk To A STR Expert First
            </PopupButton> */}
            {/* <a
              style={{
                // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                backgroundColor: mobileSize
                  ? "var(--tertiary)"
                  : "var(--tertiary)",
                textTransform: "uppercase",
                color: "var(--white)",
                padding: "1rem",
                width: mobileSize ? "100%" : "initial",
                textDecoration: "none",
                fontSize: "1rem",

                cursor: "pointer",
                display: mobileSize ? "block" : "initial",
                textAlign: "center",
                boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                borderRadius: "0.25rem",
                // display: mobileSize ? "block" : "initial",
                margin: mobileSize ? "0rem auto" : "1rem"
              }}
              data-id="learn-more_button_top"
              href="#section1"
              title="/buy"
            >
              Learn More
            </a> */}
          </div>
          <div
            className="fourth"
            style={{
              display: mobileSize ? "none" : "none",
              marginTop: "5rem"
            }}
          >
            <h3 style={{ color: "var(--white)", textAlign: "center" }}>
              Buy
              <Text
                style={{
                  fontSize: mobileSize ? "2rem" : "1.6rem",
                  fontWeight: 400,
                  color: "var(--white)"
                }}
              >
                &nbsp;the best Short-Term Rental investments
                <Text
                  style={{
                    fontSize: mobileSize ? "2rem" : "1.6rem",
                    textAlign: "center"
                  }}
                >
                  {marketPathParam && " in"}
                </Text>{" "}
                <Text
                  style={{
                    color: "var(--white)",
                    fontWeight: "500",
                    // textDecoration: "underline",
                    textAlign: "center",
                    fontSize: mobileSize ? "2rem" : "1.6rem"
                  }}
                >
                  {LiveMarkets[marketPathParam]}
                </Text>
              </Text>
              &nbsp;with top real estate agents
              <Text
                // block
                style={{
                  fontSize: mobileSize ? "2rem" : "1.6rem",
                  fontWeight: 500,
                  color: "var(--white)",
                  textAlign: "center"
                  // background: "var(--tertiary)",
                  // padding: "1rem",
                  // marginTop: "-1.75rem"
                }}
              >
                {!marketPathParam && " in "}{" "}
                {!marketPathParam && (
                  <a
                    style={{
                      textAlign: "center",
                      color: "var(--quaternary)",
                      background: "rgba(255,255,255,0.3)",
                      padding: "0.5rem",
                      marginTop: "0.2rem",
                      borderRadius: "0.5rem",
                      display: "block"
                    }}
                    href="#locations"
                  >
                    most US markets
                  </a>
                )}
              </Text>
            </h3>
          </div>
        </div>
        <div style={{ display: "block", maxWidth: "50%" }}>
          <Image
            src={`${process.env.PUBLIC_URL}/images/partner-realtor-musculine.webp`}
            alt="Chalet Partner Real Estate Agents With STR Investing Expertise"
            $mobileSize={mobileSize}
            style={{
              gridRowStart: mobileSize ? "1" : "inherit",
              maxWidth: mobileSize ? "100%" : "300px",
              display: mobileSize ? "none" : "block",
              borderRadius: "0.25rem"
            }}
          />
        </div>
      </Container>
      <Section
        id="home-statistics"
        style={{
          backgroundImage: `url('${process.env.PUBLIC_URL}/images/radial-gradient.webp')`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat"
        }}
      >
        <div className="stats">
          <span id="alt-red">Connected Investors With</span>
          <span id="highlight">100+million</span>
          <span id="alt-black">in short-term rental assets</span>
        </div>
        <VerticalDivider name="verdiv" />
        <HorizontalDivider name="hordiv" />
        <div className="stats">
          <span id="alt-black">Short-Term Rental Partners</span>
          <span id="highlight">All 50 states</span>
          <span id="alt-black">200+ markets</span>
        </div>
        <VerticalDivider name="verdiv" />
        <HorizontalDivider name="hordiv" />
        <div className="stats">
          <span id="alt-red">Analyzing</span>
          <span id="highlight">1 million+</span>
          <span id="alt-red">short-term rentals</span>
        </div>
      </Section>
      <Group id="section1">
        <Section
          style={{
            boxShadow: mobileSize ? "none" : "inherit",
            maxWidth: "100%"
          }}
        >
          <Header size={2}>
            Find Your Perfect Short-Term Rental Investment With Chalet
          </Header>

          <SplitView
            split="2:3"
            style={{
              alignItems: "start",
              maxWidth: mobileSize ? "100%" : "85vw",
              margin: "0 auto"
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "right",
                gridRowStart: mobileSize ? "1" : "inherit"
              }}
            >
              <Image
                src={`${process.env.PUBLIC_URL}/images/invest-with-experts.webp`}
                alt="Buy your Airbnb rental with Chalet"
                style={{
                  borderRadius: "0.25rem",
                  maxWidth: mobileSize ? "100%" : "300px",
                  width: "100%"
                }}
                $mobileSize={mobileSize}
              />
            </div>
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit"
              }}
            >
              <Text
                block
                align="left"

                // style={{ lineHeight: "2rem" }}
                // lineHeight="4.5rem"
              >
                Are you looking for a reliable and experienced real estate agent
                to help you find the perfect short-term rental investment{" "}
                {marketPathParam && "in"} {LiveMarkets[marketPathParam]}?
              </Text>
              <Text block align="left">
                Chalet partner real estate agents know which neighborhoods
                perform the best from a short-term rental perspective, and have
                a lot of experience working with real estate investors.
              </Text>
              {marketPathParam && (
                <a
                  href={`/investor-guides/${marketPathParam}/`}
                  target="_blank"
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "var(--quinary)"
                  }}
                  rel="noreferrer"
                >
                  Read our Investor Guide To Airbnb Rentals In{" "}
                  {LiveMarkets[marketPathParam]}
                </a>
                // <Text
                //   align="left"
                //   style={{
                //     cursor: "pointer",
                //     textDecoration: "underline",
                //     color: "var(--quinary)"
                //   }}
                //   onClick={handleInvestorGuideClick}
                // >
                //   Read our Investor Guide To Airbnb Rentals In{" "}
                //   {LiveMarkets[marketPathParam]}
                // </Text>
              )}
            </div>
          </SplitView>
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group>
      <Group
        style={{
          // background:
          //   "radial-gradient(4291.36% 46161.46% at 2886.22% -7976.07%, #DB005F 36.46%, #2F354B 67.71%, #FB929D 71.35%)",
          // background: "var(--radialGradient)",
          background: "var(--tertiary)",
          maxWidth: "100%"
        }}
      >
        <Section
          style={{
            boxShadow: "none",
            background: "none",
            maxWidth: "100%"
          }}
        >
          <Header size={2} color={Color.white}>
            Local Experts With a Proven Track Record
          </Header>
          <SplitView
            split="2:1"
            style={{
              alignItems: "start",
              maxWidth: mobileSize ? "100%" : "85vw",
              margin: "0 auto"
            }}
          >
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit"
              }}
            >
              <Text
                block
                align="left"
                style={{ lineHeight: "2.5rem" }}
                color={Color.white}
              >
                Our real estate agents are not only local experts but also
                top-performing real estate agents in the{" "}
                {LiveMarkets[marketPathParam]} area.
              </Text>
              <Text
                block
                align="left"
                style={{ lineHeight: "2.5rem" }}
                color={Color.white}
              >
                They have the skills and expertise to help you make informed
                investment decisions and reach your investment goals.
              </Text>
              <Text
                block
                align="left"
                style={{ lineHeight: "2.5rem" }}
                color={Color.white}
              >
                Understanding the local short-term rental regulations can be
                challenging, but our agents are equipped to guide you through
                these complexities. Whether you&apos;re navigating licensing
                requirements, tax obligations, or zoning restrictions, our
                agents provide the resources you need for smooth sailing.
              </Text>
              {marketPathParam && (
                <a
                  href={`/rental-regulations/${marketPathParam}/`}
                  target="_blank"
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "var(--quinary)"
                  }}
                  rel="noreferrer"
                >
                  Airbnb Regulation in {LiveMarkets[marketPathParam]}
                </a>
              )}
            </div>
            <Image
              src={`${process.env.PUBLIC_URL}/images/1031-exchange.webp`}
              alt="Local STR Experts With a Proven Track Record"
              $mobileSize={mobileSize}
              style={{
                display: "flex",
                gridRowStart: mobileSize ? "1" : "inherit",
                justifyContent: "right",
                margin: "0 auto",
                maxWidth: mobileSize ? "100vw" : "300px"
              }}
            />
          </SplitView>
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group>
      <Group
        style={{
          paddingBottom: "0rem",
          maxWidth: "100%",
          paddingTop: "0rem",
          backgroundImage: `url("${process.env.PUBLIC_URL}/images/blue-fade.webp")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        }}
      >
        <Section
          style={{
            boxShadow: "none",
            maxWidth: "100%",
            background: "none"
          }}
        >
          <Header size={2}>
            Validated Rental Numbers for Your Peace of Mind
          </Header>
          <SplitView
            split="1:2"
            style={{
              alignItems: "start",
              maxWidth: mobileSize ? "100%" : "85vw",
              margin: "0 auto"
            }}
          >
            <Image
              src={`${process.env.PUBLIC_URL}/images/graph.webp`}
              alt="Top locations for Airbnb Investment"
              $mobileSize={mobileSize}
              style={{
                padding: "0rem",
                // pos/ition: "absolute",
                // left: 0,
                // bottom: mobileSize ? "-8rem" : "-3rem",
                maxWidth: mobileSize ? "400px" : "100%",
                // display: "block",
                borderRadius: "0.25rem"
              }}
            />
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit"
              }}
            >
              <Text block align="left">
                Our partners validate the rental numbers so that you can make
                investment decisions with confidence.
              </Text>
              <Text block align="left">
                They will help you understand the potential return on investment
                and make sure that your investment is based on accurate and
                trustworthy information.
              </Text>
            </div>
          </SplitView>
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group>
      <Section
        style={{
          boxShadow: "none",
          // background: `url("${process.env.PUBLIC_URL}/images/blue-fade.webp")`,
          // background: `url("${process.env.PUBLIC_URL}/images/about2.webp")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          maxWidth: "100%",
          padding: 0,
          position: "relative"
        }}
      >
        <Image
          src={`${process.env.PUBLIC_URL}/images/testimonial-element.png`}
          alt="decoration"
          style={{
            width: "fit-content",
            position: "absolute",
            right: "15rem",
            top: "1rem",
            display: mobileSize ? "none" : "block"
          }}
        />
        <Image
          src={`${process.env.PUBLIC_URL}/images/testimonial-element.png`}
          alt="decoration"
          style={{
            width: "fit-content",
            position: "absolute",
            left: "15rem",
            top: "1rem",
            display: mobileSize ? "none" : "block"
          }}
        />
        <Header size={2} style={{ margin: "2rem auto 0" }}>
          Testimonials
        </Header>
        <div
          style={{
            width: "100%",
            margin: "0 auto",
            display: "block"
          }}
        >
          <Slider {...sliderSettings}>
            <div
              className="testimonial"
              style={
                {
                  // background: `url("${process.env.PUBLIC_URL}/images/markets-bg.webp")`
                }
              }
            >
              <Header
                size={3}
                // color={Color.quinary}
                style={{ paddingBottom: "1rem", textAlign: "center" }}
              >
                Justin B.
              </Header>
              <SplitView split="2:3">
                <Image
                  src="https://a0.muscache.com/im/pictures/prohost-api/Hosting-1067591824691586398/original/bce45fe4-ec7e-4cd2-b3a5-320904fea506.jpeg?im_w=1200"
                  alt="Airbnb & VRBO listings setup "
                  style={{
                    borderRadius: "0.5rem",
                    maxWidth: mobileSize ? "100%" : "500px",
                    objectFit: "cover",
                    margin: "0rem auto",
                    display: "block",
                    height: "auto",
                    // maxHeight: mobileSize ? "100%" : "60px",
                    // minHeight: mobileSize ? "100%" : "60px",
                    // width: "100%",
                    verticalAlign: "middle"
                  }}
                  $mobileSize={mobileSize}
                />
                <div>
                  <Text
                    block
                    style={{
                      fontSize: "1.5rem",
                      // lineHeight: "1.7rem",
                      paddingBottom: "1rem"
                    }}
                  >
                    My wife and I found Chalet when we were in search of a short
                    term rental property, but needed some professional help.
                    Chalet team replied back quickly and referred me to a local
                    agent in the market where we were searching. It was a huge
                    help because our agent was well versed in investment
                    properties and provided immediate value in helping us find
                    something specific in our market.
                  </Text>
                  <Text block style={{ fontSize: "1.5rem" }}>
                    In the end, we closed on the property and have been
                    operating it for a few months now! It&apos;s been a great
                    couple of months and we couldn&apos;t be happier with the
                    way it all came together....
                    <Text
                      style={{
                        fontSize: "1.5rem",
                        lineHeight: "1.5rem",
                        fontWeight: 400
                      }}
                    >
                      and it all started with Chalet!
                    </Text>
                  </Text>
                </div>
              </SplitView>
              <a
                href="https://www.airbnb.com/rooms/1067591824691586398"
                style={{
                  display: "block",
                  margin: "1rem auto",
                  color: "var(--white)",
                  borderRadius: "0.5rem",
                  textDecoration: "none",
                  textAlign: "center",
                  padding: "0.5rem",
                  background: "var(--linearGradient)"
                }}
              >
                Book a stay at Justin&apos;s home
              </a>
            </div>
            <div
              className="testimonial"
              style={{
                background: `url("${process.env.PUBLIC_URL}/images/markets-bg.webp")`
              }}
            >
              <Header
                size={3}
                // color={Color.quinary}
                style={{ paddingBottom: "1rem", textAlign: "center" }}
              >
                Jimmy O.
              </Header>
              <SplitView>
                <Image
                  src="https://a0.muscache.com/im/pictures/hosting/Hosting-U3RheVN1cHBseUxpc3Rpbmc6MTAyNDA5NjMzMjEwNTM3NzgyNA%3D%3D/original/aad2a319-59e6-40c1-91fb-1b831e327143.jpeg?im_w=1440"
                  alt="Airbnb & VRBO listings setup "
                  style={{
                    borderRadius: "0.5rem",
                    maxWidth: mobileSize ? "100%" : "500px",
                    objectFit: "cover",
                    margin: "0rem auto",
                    display: "block",
                    height: "auto",
                    // maxHeight: mobileSize ? "100%" : "60px",
                    // minHeight: mobileSize ? "100%" : "60px",
                    // width: "100%",
                    verticalAlign: "middle"
                  }}
                  $mobileSize={mobileSize}
                />
                <div>
                  <Text
                    block
                    style={{
                      fontSize: "1.5rem",
                      // lineHeight: "1.5rem",
                      paddingBottom: "1rem"
                    }}
                  >
                    Chalet hooked me up with that amazing realtor in Tampa with
                    great STR knowledge. Plus, they set me up with some folks
                    for a Cost Segregation study that saved me a ton on taxes.
                  </Text>
                  <Text block style={{ fontSize: "1.5rem" }}>
                    Seriously, their service is top-notch.
                  </Text>
                </div>
              </SplitView>
              <a
                href="https://www.airbnb.com/rooms/1024096332105377824"
                style={{
                  display: "block",
                  margin: "1rem auto",
                  color: "var(--white)",
                  borderRadius: "0.5rem",
                  textDecoration: "none",
                  textAlign: "center",
                  padding: "0.5rem",
                  background: "var(--linearGradient)"
                }}
              >
                Book a stay at Jimmy&apos;s home
              </a>
            </div>
            <div
              className="testimonial"
              style={{
                background: `url("${process.env.PUBLIC_URL}/images/markets-bg.webp")`
              }}
            >
              <Header
                size={3}
                // color={Color.quinary}
                style={{ paddingBottom: "1rem", textAlign: "center" }}
              >
                Dorinda A.
              </Header>
              <SplitView>
                <Image
                  src="https://a0.muscache.com/im/pictures/miso/Hosting-564716174289419914/original/9ecc5107-abe9-4729-bee6-d3c1f298fb64.jpeg?im_w=1440"
                  alt="Airbnb & VRBO listings setup "
                  style={{
                    borderRadius: "0.5rem",
                    maxWidth: mobileSize ? "100%" : "500px",
                    objectFit: "cover",
                    margin: "0rem auto",
                    display: "block",
                    height: "auto",
                    // maxHeight: mobileSize ? "100%" : "60px",
                    // minHeight: mobileSize ? "100%" : "60px",
                    // width: "100%",
                    verticalAlign: "middle"
                  }}
                  $mobileSize={mobileSize}
                />
                <div>
                  <Text
                    block
                    style={{
                      fontSize: "1.5rem",
                      // lineHeight: "1.5rem",
                      paddingBottom: "1rem"
                    }}
                  >
                    My husband and had a dream of moving to a an active,
                    outdoors community for our retirement. But, instead of just
                    buying an expensive home, we needed a property that could
                    also provide flexible income. That is where Chalet stepped
                    in. Their comprehensive data research was compelling. But,
                    the concierge, personal approach was out of this world. They
                    used our personal preferences to hone in on neighborhoods
                    that suited us. Then, they matched us with an amazing
                    realtor, who found the actual home that was perfect for us.
                  </Text>
                  <Text block style={{ fontSize: "1.5rem" }}>
                    Even after we bought the house, Chalet continues to provide
                    competent service providers. We have used them to find our
                    cleaning service, handyman services, etc. I would highly
                    recommend Chalet to help you with your investment decisions.
                  </Text>
                </div>
              </SplitView>
              <a
                href="https://www.airbnb.com/rooms/564716174289419914"
                style={{
                  display: "block",
                  margin: "1rem auto",
                  color: "var(--white)",
                  borderRadius: "0.5rem",
                  textDecoration: "none",
                  textAlign: "center",
                  padding: "0.5rem",
                  background: "var(--linearGradient)"
                }}
              >
                Book a stay at Dorinda&apos;s home
              </a>
            </div>
          </Slider>
        </div>
      </Section>
      <Group style={{ background: "var(--tertiary)" }}>
        <Section
          style={{
            maxWidth: "100%",
            background: "none",
            boxShadow: "none"
          }}
        >
          <Header
            // style={{ fontWeight: 400, color: "var(--white)" }}
            size={2}
            color={Color.white}
          >
            Connect With the Right Vendors for Success
          </Header>
          <SplitView
            split="2:1"
            style={{
              alignItems: "start",
              maxWidth: mobileSize ? "100%" : "85vw",
              margin: "0 auto"
              // margin: "0 auto"
            }}
          >
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit",
                paddingBottom: mobileSize ? "inherit" : "0",
                paddingTop: mobileSize ? "inherit" : "0"
              }}
            >
              <Text block align="left" color={Color.white}>
                Our real estate agents have a well-established network of local
                vendors in the {LiveMarkets[marketPathParam]} market who can
                help you manage and maintain your short-term rental property
                effectively.
              </Text>
              <Text block align="left" color={Color.white}>
                From property management to guest services, they can connect you
                with the right vendors to ensure your property is successful.
              </Text>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: mobileSize ? "center" : "left",
                gridRowStart: mobileSize ? "1" : "inherit"
              }}
            >
              <Image
                src={`${process.env.PUBLIC_URL}/images/chalet-partner-vendors.webp`}
                alt="Airbnb Rental Investor Guides. Free Market Reports. Median Revenue, ADR, Occupacny rates"
                style={{
                  padding: "0rem",
                  borderRadius: "0.25rem",
                  maxWidth: mobileSize ? "100%" : "300px",
                  width: "100%"
                }}
                $mobileSize={mobileSize}
              />
            </div>
          </SplitView>
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group>
      <Group style={{ background: "var(--white)" }}>
        <Section
          style={{
            maxWidth: "100%",
            background: "none",
            boxShadow: "none"
          }}
        >
          <Header
            // style={{ fontWeight: 400, color: "var(--white)" }}
            size={2}
            color={Color.tertiary}
          >
            Partner With Experienced Short-Term Rental Investors
          </Header>
          <SplitView
            split="1:2"
            style={{
              alignItems: "start",
              maxWidth: mobileSize ? "100%" : "85vw",
              margin: "0 auto"
              // margin: "0 auto"
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: 0,
                gridRowStart: mobileSize ? "1" : "inherit"
              }}
            >
              <Image
                src={`${process.env.PUBLIC_URL}/images/partner-realtor-musculine.webp`}
                alt="Airbnb Rental Investor Guides. Free Market Reports. Median Revenue, ADR, Occupacny rates"
                style={{
                  padding: "0rem",
                  borderRadius: "0.25rem",
                  maxWidth: mobileSize ? "100%" : "300px"
                  // width: "100%"
                }}
                $mobileSize={mobileSize}
              />
            </div>
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit",
                paddingBottom: mobileSize ? "inherit" : "0",
                paddingTop: mobileSize ? "inherit" : "0"
              }}
            >
              <Text block align="left" color={Color.tertiary}>
                At Chalet, we bring you the expertise of real estate agents who
                have invested in the short-term rental market themselves.
              </Text>
              <Text block align="left" color={Color.tertiary}>
                They understand the ins and outs of the business and can provide
                valuable insights and advice based on their own experiences.
              </Text>
            </div>
          </SplitView>
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group>
      <Group
        style={{
          position: "relative"
        }}
      >
        <Section
          style={{
            maxWidth: "100%",
            background: "var(--tertiary)",

            boxShadow: "none"
          }}
        >
          <Header
            // style={{ fontWeight: 400, color: "var(--white)" }}
            size={2}
            color={Color.white}
            style={{ zIndex: 200, position: "relative" }}
          >
            Connect with a Short-Term Rental Agent Now
          </Header>
          <Image
            src={`${process.env.PUBLIC_URL}/images/vacation_home.webp`}
            alt="Airbnb Rental Investor Guides. Free Market Reports. Median Revenue, ADR, Occupacny rates"
            style={{
              padding: "0rem",
              position: "absolute",
              left: 0,
              top: 0,
              opacity: "0.1",
              // zIndex: "0",
              // right: 0,
              // borderRadius: "2rem",
              maxWidth: mobileSize ? "100%" : "100%",
              width: "100%"
            }}
            $mobileSize={mobileSize}
          />
          <div
            style={{
              gridRowStart: mobileSize ? "2" : "inherit",
              paddingBottom: mobileSize ? "inherit" : "0",
              paddingTop: mobileSize ? "inherit" : "0",
              position: "relative",
              zIndex: 1
            }}
          >
            <Text block align="center" color={Color.white}>
              Instantly connect with an Airbnb real estate agent and start
              realizing your investment goals in the{" "}
              {LiveMarkets[marketPathParam]} short-term rental market.
            </Text>
            <PopupButton
              id="DU51pTsh"
              hidden={{
                market_of_interest:
                  LiveMarkets[marketPathParam] || "Not Specified",
                source_page: urlSlug
              }}
              shareGaInstance="true"
              className="connect-with-realtor_popup_bottom"
              style={{
                // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                // backgroundColor: "var(--tertiary)",
                background:
                  "radial-gradient(4291.36% 46161.46% at 2886.22% -7976.07%, #BC6A81 36.46%, #695979 66.15%, #db005f 71.35%)",
                textTransform: "uppercase",
                display: "block",
                color: "var(--white)",
                padding: "1rem",
                border: 0,
                width: mobileSize ? "100%" : "initial",
                textDecoration: "none",
                // display: mobileSize ? "block" : "initial",
                fontSize: "1rem",
                cursor: "pointer",
                textAlign: "center",
                boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                borderRadius: "0.25rem",
                // display: mobileSize ? "block" : "initial",
                margin: mobileSize ? "1rem auto" : "1rem auto"
              }}
            >
              Connect With An Airbnb Realtor
            </PopupButton>
          </div>
        </Section>
      </Group>
    </>
  );
};

export default RealEstateAgents;
