import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
  defaults
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Colors } from "./GlobalStyle";
import {
  compactNotation,
  numberNotation,
  percentNotation
} from "../utils/numberFormatters";
import isNullOrUndefined from "../utils/isNullOrUndefined";
import { Font } from "./Text";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler
);

defaults.font.family = Font.SourceSansPro;

interface AreaChartProps {
  color?: Colors;
  data: Record<string, any>;
  min?: number | null;
  percent?: boolean;
  y?: boolean;
  yAxisPrecision?: number;
  yAxisTickCount?: number;
}

const AreaChart: React.FC<AreaChartProps> = ({
  color = Colors.quaternary,
  data,
  min = 0,
  y = true,
  percent,
  yAxisPrecision = 0,
  yAxisTickCount = 5
}: AreaChartProps) => {
  const labels: string[] = [];
  const values: number[] = [];

  Object.entries(data).forEach(([key, value]) => {
    if (key !== "market") {
      labels.push(key.replace("Bedrooms", "BR"));
      values.push(parseFloat(String(value)));
    }
  });

  return (
    <Line
      data={{
        labels,
        datasets: [
          {
            labels,
            data: values,
            borderColor: color,
            backgroundColor: `${color}40`, // Add 40% opacity to the color
            fill: true, // This enables the area fill
            tension: 0.2
          }
        ] as any
      }}
      options={{
        aspectRatio: 1.75,
        elements: {
          line: {
            tension: 0.2
          },
          point: {
            radius: 0
          }
        },
        interaction: {
          intersect: false
        },
        layout: {
          padding: {
            top: 16
          }
        },
        plugins: {
          datalabels: {
            display: false
          },
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: (context: any) => {
                if (percent) {
                  return percentNotation(context.parsed.y / 100, 1);
                }
                return numberNotation(context.parsed.y);
              }
            }
          }
        },
        scales: {
          x: {
            display: true,
            grid: {
              display: false
            },
            ticks: {
              callback(value: any, index: any) {
                const date = new Date(labels[index]);
                return `${String(date.getMonth() + 1).padStart(
                  2,
                  "0"
                )}-${date.getFullYear()}`;
              },
              maxRotation: 45, // Add rotation angle
              minRotation: 45 // Add rotation angle
            }
          },
          y: {
            display: y,
            min: isNullOrUndefined(min) ? undefined : min,
            position: "right",
            ticks: {
              callback: (value: any) => {
                if (percent) {
                  return percentNotation(value / 100, yAxisPrecision);
                }
                return compactNotation(value, yAxisPrecision);
              },
              count: yAxisTickCount
            }
          }
        }
      }}
    />
  );
};

export default AreaChart;
